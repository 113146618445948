enum TabOption {
  PLANNER = "planner",
  CODE = "code",
  BROWSER = "browser",
  JUPYTER = "jupyter",
  TERMINAL = "terminal",
  CHAT = "chat",
  EDIT = "edit",
  MESSAGE_HISTORY = "message_history",
  PROBLEM_INFO = "problem_info",
}

type TabType =
  | TabOption.PLANNER
  | TabOption.CODE
  | TabOption.BROWSER
  | TabOption.JUPYTER
  | TabOption.TERMINAL
  | TabOption.CHAT
  | TabOption.EDIT
  | TabOption.MESSAGE_HISTORY
  | TabOption.PROBLEM_INFO;

const AllTabs = [
  TabOption.CODE,
  TabOption.BROWSER,
  TabOption.PLANNER,
  TabOption.JUPYTER,
  TabOption.TERMINAL,
  TabOption.CHAT,
  TabOption.EDIT,
  TabOption.MESSAGE_HISTORY,
  TabOption.PROBLEM_INFO,
];

export { AllTabs, TabOption, type TabType };
