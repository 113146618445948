import {
  addAssistantMessage,
  addCodeOrCommandsUsed,
  addUserMessage,
} from "#/state/chatSlice";
import { setCode, setActiveFilepath } from "#/state/codeSlice";
import { appendInput } from "#/state/commandSlice";
import { appendJupyterInput } from "#/state/jupyterSlice";
import { setCurStatusMessage } from "#/state/statusSlice";
import { setRootTask } from "#/state/taskSlice";
import store from "#/store";
import ActionType from "#/types/ActionType";
import { ActionMessage, StatusMessage } from "#/types/Message";
import { SocketMessage } from "#/types/ResponseType";
import { handleObservationMessage } from "./observations";
import { getRootTask } from "./taskService";

const messageActions = {
  [ActionType.BROWSE]: (message: ActionMessage) => {
    store.dispatch(addAssistantMessage(message));
  },
  [ActionType.BROWSE_INTERACTIVE]: (message: ActionMessage) => {
    if (message.args.thought) {
      const updatedMessage = { ...message, message: message.args.thought };
      store.dispatch(addAssistantMessage(updatedMessage));
    } else {
      store.dispatch(addAssistantMessage(message));
    }
  },
  [ActionType.WRITE]: (message: ActionMessage) => {
    const { path, content } = message.args;
    store.dispatch(setActiveFilepath(path));
    store.dispatch(setCode(content));
  },
  [ActionType.MESSAGE]: (message: ActionMessage) => {
    if (message.source === "user") {
      store.dispatch(
        addUserMessage({
          content: message.args.content,
          imageUrls: [],
          timestamp: message.timestamp,
        }),
      );
    } else {
      const updatedMessage = { ...message, message: message.args.content };
      store.dispatch(addAssistantMessage(updatedMessage));
    }
  },
  [ActionType.FINISH]: (message: ActionMessage) => {
    store.dispatch(addAssistantMessage(message));
  },
  [ActionType.REJECT]: (message: ActionMessage) => {
    store.dispatch(addAssistantMessage(message));
  },
  [ActionType.DELEGATE]: (message: ActionMessage) => {
    store.dispatch(addAssistantMessage(message));
  },
  [ActionType.RUN]: (message: ActionMessage, disabled = false) => {
    if (
      !message.args.is_confirmed ||
      message.args.is_confirmed === "awaiting_confirmation"
    ) {
      const messages = [];
      if (message.args.thought) {
        messages.push(`${message.args.thought}`);
      }
      if (message.args.command) {
        messages.push(`\`\`\`\`bash\n${message.args.command}\n\`\`\`\`\n`);
      }
      const totalMessage = messages.join("\n\n\n");
      const updatedMessage = { ...message, message: totalMessage, disabled };
      store.dispatch(addAssistantMessage(updatedMessage));
      store.dispatch(
        addCodeOrCommandsUsed({
          content: message.args.command,
          runType: "bash",
        }),
      );
    } else if (
      !message.args.is_confirmed ||
      message.args.is_confirmed === "confirmed"
    ) {
      store.dispatch(appendInput(message.args.command));
    }
  },
  [ActionType.RUN_IPYTHON]: (message: ActionMessage, disabled = false) => {
    if (
      !message.args.is_confirmed ||
      message.args.is_confirmed === "awaiting_confirmation"
    ) {
      const messages = [];
      if (message.args.thought) {
        messages.push(`${message.args.thought}`);
      }
      if (message.args.code) {
        messages.push(`\`\`\`\`python\n${message.args.code}\n\`\`\`\`\n`);
      }
      const totalMessage = messages.join("\n\n\n");
      const updatedMessage = { ...message, message: totalMessage, disabled };
      store.dispatch(addAssistantMessage(updatedMessage));
      store.dispatch(
        addCodeOrCommandsUsed({
          content: message.args.code,
          runType: "python",
        }),
      );
    } else if (
      !message.args.is_confirmed ||
      message.args.is_confirmed === "confirmed"
    ) {
      store.dispatch(appendJupyterInput(message.args.code));
    }
  },
  [ActionType.ADD_TASK]: () => {
    getRootTask().then((fetchedRootTask) =>
      store.dispatch(setRootTask(fetchedRootTask)),
    );
  },
  [ActionType.MODIFY_TASK]: () => {
    getRootTask().then((fetchedRootTask) =>
      store.dispatch(setRootTask(fetchedRootTask)),
    );
  },
};

export function handleActionMessage(message: ActionMessage, disabled = false) {
  if (message.action in messageActions) {
    const actionFn =
      messageActions[message.action as keyof typeof messageActions];
    actionFn(message, disabled);
  }
}

export function handleStatusMessage(message: StatusMessage) {
  const msg = message.status == null ? "" : message.status.trim();
  store.dispatch(
    setCurStatusMessage({
      ...message,
      status: msg,
    }),
  );
}

export function handleAssistantMessage(data: string | SocketMessage) {
  let socketMessage: SocketMessage;

  if (typeof data === "string") {
    socketMessage = JSON.parse(data) as SocketMessage;
  } else {
    socketMessage = data;
  }

  if ("action" in socketMessage) {
    handleActionMessage(socketMessage);
  } else if ("status" in socketMessage) {
    handleStatusMessage(socketMessage);
  } else {
    handleObservationMessage(socketMessage);
  }
}
