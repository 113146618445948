import React, { useCallback, useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { useDispatch } from "react-redux";
import Errors from "#/components/Errors";
import { Container, Orientation } from "#/components/Resizable";
import Workspace from "#/components/Workspace";
import "./App.css";
import AgentStatusBar from "./components/AgentStatusBar";
import Session from "#/services/session";
// import { settingsAreUpToDate } from "#/services/settings";
import TrajectoryBrowser from "./components/modals/trajectory-browser/TrajectoryBrowser";
import AppHeader from "./AppHeader";
import "@xyflow/react/dist/style.css";
import { FinishTaskModal } from "./components/modals/finish-task/FinishTaskModal";
import Chatspace from "#/components/Chatspace";
import { useRestoreTrajectory } from "./hooks/useRestoreTrajectory";
import { fetchLatestTrajectory } from "./services/fileService";
import { setLoadedTrajectory } from "./state/trajectorySlice";
import { LoadRepoModal } from "./components/modals/load-repo/LoadRepoModal";

function Controls(): JSX.Element {
  return (
    <div className="flex w-full p-4 bg-neutral-900 items-center shrink-0 justify-between">
      <AgentStatusBar />
    </div>
  );
}

// React.StrictMode will cause double rendering, use this to prevent it
let initOnce = false;

function App(): JSX.Element {
  useEffect(() => {
    if (initOnce) return;
    initOnce = true;

    // if (!settingsAreUpToDate()) {
    //   onSettingsModalOpen();
    // } else {
    //   Session.startNewSession();
    // }
    Session.startNewSession();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useDispatch();
  const restoreTrajectory = useRestoreTrajectory();

  const fetchTrajectory = useCallback(async () => {
    const tr = await fetchLatestTrajectory();
    dispatch(setLoadedTrajectory(tr));
    restoreTrajectory(Object.values(tr).flat(), false);
  }, [dispatch, restoreTrajectory]);

  useEffect(() => {
    fetchTrajectory();
  }, [fetchTrajectory]);

  return (
    <div className="h-screen w-screen flex flex-col">
      <div className="flex flex-col grow bg-neutral-900 text-white min-h-0">
        <AppHeader />
        <Container
          orientation={Orientation.HORIZONTAL}
          className="grow h-full min-h-0 min-w-0 px-3 pt-3"
          initialSize={700}
          firstChild={<Chatspace />}
          firstClassName="rounded-xl overflow-hidden border border-neutral-600"
          secondChild={<Workspace />}
          secondClassName="rounded-xl overflow-hidden border border-neutral-600"
        />
      </div>
      <Controls />
      <LoadRepoModal />
      <FinishTaskModal />
      <TrajectoryBrowser />
      <Errors />
      <Toaster />
    </div>
  );
}

export default App;
