/* eslint-disable no-nested-ternary */
import { Textarea, Select, SelectItem } from "@nextui-org/react";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "#/store";
import BaseModal from "../base-modal/BaseModal";
import { addUserMessage, setShowFinishTask } from "#/state/chatSlice";
import { sendChatMessage } from "#/services/chatService";
import { changeAgentState } from "#/services/agentStateService";
import AgentState from "#/types/AgentState";
import ActionType from "#/types/ActionType";

export function FinishTaskModal() {
  const { showFinishTask } = useSelector((state: RootState) => state.chat);

  const [outcome, setOutcome] = useState("");
  const [notes, setNotes] = useState("");
  const [loading, setLoading] = useState(false);

  const originalNotes = "";
  const originalOutcome = "finished";

  const dispatch = useDispatch();

  const resetResponse = useCallback(() => {
    setNotes(originalNotes);
    setOutcome(originalOutcome);
  }, [originalNotes, originalOutcome]);

  useEffect(() => {
    resetResponse();
  }, [resetResponse]);

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    const content = `Ended the task with the following outcome: **${outcome.toUpperCase()}** \n\n Task ended with the following notes:  \n\`\`\`\`md\n${notes}\n\`\`\`\``;
    const timestamp = new Date().toISOString();
    dispatch(
      addUserMessage({
        content,
        imageUrls: [],
        timestamp,
      }),
    );
    const actionType =
      outcome === "finished"
        ? ActionType.FINISH
        : outcome === "reject"
          ? ActionType.REJECT
          : ActionType.ERROR;
    const messageContent = `${outcome.toUpperCase()}: ${notes}`;
    sendChatMessage(messageContent, [], timestamp, notes, actionType);

    if (outcome === "finished") {
      changeAgentState(AgentState.FINISHED);
    } else if (outcome === "reject") {
      changeAgentState(AgentState.REJECTED);
    } else {
      changeAgentState(AgentState.ERROR);
    }
    setLoading(false);

    dispatch(setShowFinishTask(false));
  }, [outcome, notes, dispatch]);

  return (
    <BaseModal
      isOpen={showFinishTask}
      title="Finish Run"
      onOpenChange={() => {}}
      isDismissable={false}
      actions={[
        {
          label: "Submit",
          className: "bg-primary rounded-lg",
          action: handleSubmit,
          closeAfterAction: true,
          isDisabled: !notes || loading,
          isLoading: loading,
        },
        {
          label: "Cancel",
          className: "bg-secondary rounded-lg",
          action: () => dispatch(setShowFinishTask(false)),
          closeAfterAction: true,
          isDisabled: loading,
        },
      ]}
    >
      <Select
        selectedKeys={[outcome]}
        value={outcome}
        onChange={(e) => setOutcome(e.target.value)}
        aria-label="Outcome"
      >
        <SelectItem key="finished" aria-label="Finished">
          Finished
        </SelectItem>
        <SelectItem key="error" aria-label="Error">
          Errored
        </SelectItem>
        <SelectItem key="bad_data" aria-label="Bad Data">
          Bad Data
        </SelectItem>
      </Select>
      <Textarea
        label="Notes"
        value={notes}
        onChange={(e) => {
          setNotes(e.target.value);
        }}
        isDisabled={loading}
      />
    </BaseModal>
  );
}
