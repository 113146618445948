import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { VscArrowDown } from "react-icons/vsc";
import Chat from "./Chat";
import TypingIndicator from "./TypingIndicator";
import { RootState } from "#/store";
import AgentState from "#/types/AgentState";
import { sendChatMessage } from "#/services/chatService";
import { addUserMessage } from "#/state/chatSlice";
import { I18nKey } from "#/i18n/declaration";
import { useScrollToBottom } from "#/hooks/useScrollToBottom";
import ChatInput from "./ChatInput";

interface ScrollButtonProps {
  onClick: (key?: string) => void;
  icon: JSX.Element;
  label: string;
  // eslint-disable-next-line react/require-default-props
  disabled?: boolean;
}

function ScrollButton({
  onClick,
  icon,
  label,
  disabled = false,
}: ScrollButtonProps): JSX.Element {
  return (
    <button
      type="button"
      className="relative border-1 text-xs rounded px-2 py-1 border-neutral-600 bg-neutral-700 cursor-pointer select-none"
      onClick={() => onClick()}
      disabled={disabled}
    >
      <div className="flex items-center">
        {icon} <span className="inline-block">{label}</span>
      </div>
    </button>
  );
}

function ChatInterface() {
  const dispatch = useDispatch();
  const { messages } = useSelector((state: RootState) => state.chat);
  const { curAgentState } = useSelector((state: RootState) => state.agent);

  const handleSendMessage = (content: string, imageUrls: string[]) => {
    const timestamp = new Date().toISOString();
    dispatch(
      addUserMessage({
        content,
        imageUrls,
        timestamp,
      }),
    );
    sendChatMessage(content, imageUrls, timestamp);
  };

  const { t } = useTranslation();
  const scrollRef = useRef<HTMLDivElement>(null);

  const { scrollDomToBottom, onChatBodyScroll, hitBottom } =
    useScrollToBottom(scrollRef);

  return (
    <div className="flex flex-col w-full bg-neutral-800">
      <div className="flex-1 flex flex-col relative min-h-0">
        <div
          ref={scrollRef}
          className="overflow-y-auto p-3"
          onScroll={(e) => onChatBodyScroll(e.currentTarget)}
          aria-label={t(I18nKey.CHAT_INTERFACE$CHAT_CONVERSATION)}
        >
          <Chat messages={messages} curAgentState={curAgentState} />
        </div>
      </div>

      <div className="relative">
        <div className="absolute bottom-2 left-0 right-0 flex items-center justify-center">
          {!hitBottom && (
            <ScrollButton
              onClick={scrollDomToBottom}
              icon={<VscArrowDown className="inline mr-2 w-3 h-3" />}
              label={t(I18nKey.CHAT_INTERFACE$TO_BOTTOM)}
            />
          )}
          {hitBottom && curAgentState === AgentState.RUNNING && (
            <TypingIndicator />
          )}
        </div>
      </div>

      {curAgentState === AgentState.AWAITING_USER_NUDGE && (
        <ChatInput disabled={false} onSendMessage={handleSendMessage} />
      )}
    </div>
  );
}

export default ChatInterface;
