import React, { useEffect, useMemo } from "react";
import { ReactFlow, Node, Edge, Position } from "@xyflow/react";

import { Button, ButtonGroup, Input } from "@nextui-org/react";
import { useDispatch, useSelector } from "react-redux";
import BaseModal from "../base-modal/BaseModal";
import { RootState } from "#/store";
import {
  setLoadedTrajectory,
  setShowTrajectoryBrowser,
} from "#/state/trajectorySlice";
import { TrajectoryLegend } from "./TrajectoryLegend";
import {
  TrajectoryJson,
  TRAJECTORY_STATUS_TO_RGB_MAP,
  TrajectoryStep,
} from "./types";
import { TrajectoryStepDetails } from "./TrajectoryStepDetails";
import {
  getLabelForTrajectoryStep,
  getStatusForTrajectoryStep,
} from "./helper";
import toast from "#/utils/toast";
import { useRestoreTrajectory } from "#/hooks/useRestoreTrajectory";

enum TrajectoryViewMode {
  Tree = "tree",
  Line = "line",
}

function TrajectoryBrowser() {
  const [trajectory, setTrajectory] = React.useState<
    TrajectoryJson | undefined
  >(undefined);
  const [selectedStep, setSelectedStep] = React.useState<number | undefined>(
    undefined,
  );
  const [viewMode, setViewMode] = React.useState<TrajectoryViewMode>(
    TrajectoryViewMode.Tree,
  );

  const { loadedTrajectory } = useSelector(
    (state: RootState) => state.trajectory,
  );

  const restoreTrajectoryToStep = useRestoreTrajectory();

  useEffect(() => {
    if (loadedTrajectory) {
      setTrajectory(loadedTrajectory);
    }
  }, [loadedTrajectory]);

  const stepMap = useMemo(() => {
    if (!trajectory) {
      return {};
    }
    try {
      const map: Record<string, TrajectoryStep> = {};

      Object.values(trajectory).forEach((steps) => {
        steps.forEach((step) => {
          map[step.step.toString()] = step;
        });
      });
      return map;
    } catch (e) {
      return {};
    }
  }, [trajectory]);

  const { showTrajectoryBrowser } = useSelector(
    (state: RootState) => state.trajectory,
  );

  const dispatch = useDispatch();

  const handleFileSelected = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = Array.from(e.target.files ?? [])[0];
    if (file) {
      const obj = JSON.parse(await file.text()) as TrajectoryJson;
      setTrajectory(obj);
      dispatch(setLoadedTrajectory(obj));
    }
  };

  const handleRestoreTrajectory = async (stepNumber: number) => {
    if (!loadedTrajectory) {
      return;
    }
    dispatch(setShowTrajectoryBrowser(false));
    const steps = Object.values(loadedTrajectory)
      .flat()
      .filter((tr) => tr.step <= stepNumber);
    await restoreTrajectoryToStep(steps, true);
  };

  const [nodes, edges] = useMemo(() => {
    if (!trajectory) {
      return [[], []];
    }
    try {
      const n: Node[] = [];
      const e: Edge[] = [];
      if (viewMode === TrajectoryViewMode.Tree) {
        let currentColumn = 1;
        let currentBranch = 0;
        const entryCount = Object.values(trajectory).flat().length;
        let entriesSeen = 0;
        while (entriesSeen < entryCount) {
          const entries = Object.entries(trajectory);
          for (let i = 0; i < entries.length; i += 1) {
            const [level, steps] = entries[i];
            const cb = currentBranch;
            const filteredSteps = steps.filter((step) => step.branch === cb);
            for (let idx = 0; idx < filteredSteps.length; idx += 1) {
              const step = filteredSteps[idx];

              if (idx > 0) {
                currentColumn += 1;
              }
              entriesSeen += 1;

              const { step: stepNumber } = step;

              const label = getLabelForTrajectoryStep(step);
              n.push({
                id: `${stepNumber}-${step.branch}`,
                position: {
                  x: 300 * currentColumn,
                  y: 100 * parseInt(level, 10),
                },
                data: {
                  label: `${label.slice(0, 20) + (label.length > 20 ? ".." : "")}`,
                  stepNumber,
                },
                style: {
                  background:
                    TRAJECTORY_STATUS_TO_RGB_MAP[
                      getStatusForTrajectoryStep(step)
                    ],
                },
                sourcePosition:
                  idx !== filteredSteps.length - 1
                    ? Position.Right
                    : Position.Bottom,
                targetPosition: idx > 0 ? Position.Left : Position.Top,
              });
              if (stepNumber > 0) {
                const previousLevel = parseInt(level, 10) - 1;
                let prevBranchSteps =
                  previousLevel >= 0
                    ? trajectory[previousLevel].filter((s) => s.branch === cb)
                    : [];

                if (prevBranchSteps.length === 0) {
                  prevBranchSteps = trajectory[previousLevel].filter(
                    (s) => s.branch === cb - 1,
                  );
                }
                const prevStep =
                  prevBranchSteps.length > 0
                    ? prevBranchSteps[prevBranchSteps.length - 1]
                    : { step: 0, branch: 0 };
                e.push({
                  id: `e${stepNumber - 1}-${stepNumber}-${step.branch}`,
                  source: `${prevStep.step}-${prevStep.branch}`,
                  target: `${stepNumber}-${step.branch}`,
                });
              }
            }
          }
          currentBranch += 1;
          currentColumn += 1;
        }
      } else {
        const sortedSteps = Object.values(trajectory)
          .flat()
          .sort((a, b) => a.step - b.step);
        sortedSteps.forEach((step, idx) => {
          const { step: stepNumber } = step;
          const label = getLabelForTrajectoryStep(step);
          if (idx > 0) {
            e.push({
              id: `e${stepNumber - 1}-${stepNumber}-${step.branch}`,
              source: (stepNumber - 1).toString(),
              target: stepNumber.toString(),
            });
          }
          n.push({
            id: stepNumber.toString(),
            position: { x: 300 * (idx + 1), y: 300 },
            data: {
              label: `${label.slice(0, 20) + (label.length > 20 ? ".." : "")}`,
              stepNumber,
            },
            style: {
              background:
                TRAJECTORY_STATUS_TO_RGB_MAP[getStatusForTrajectoryStep(step)],
            },
            sourcePosition: Position.Right,
            targetPosition: Position.Left,
          });
        });
      }

      return [n, e];
    } catch (e) {
      toast.error("parseError", `Error parsing trajectory file: ${e}`);
      return [[], []];
    }
  }, [trajectory, viewMode]);

  return (
    <BaseModal
      isOpen={showTrajectoryBrowser}
      title="Trajectory Viewer"
      onOpenChange={() => {}}
      actions={[]}
      isDismissable={false}
      contentClassName="max-w-[90%] max-h-[90%] w-[90%] h-[90%] p-[40px] !m-0"
      hideCloseButton={false}
      onClose={() => dispatch(setShowTrajectoryBrowser(false))}
    >
      <div className="flex justify-between">
        <Input type="file" className="w-fit" onChange={handleFileSelected} />
        <ButtonGroup>
          <Button
            isDisabled={viewMode === TrajectoryViewMode.Tree}
            onClick={() => setViewMode(TrajectoryViewMode.Tree)}
          >
            Tree
          </Button>
          <Button
            isDisabled={viewMode === TrajectoryViewMode.Line}
            onClick={() => setViewMode(TrajectoryViewMode.Line)}
          >
            Linear
          </Button>
        </ButtonGroup>
      </div>
      <div className="w-full h-full border border-white">
        <ReactFlow
          nodes={nodes}
          edges={edges}
          nodesDraggable={false}
          nodesConnectable={false}
          connectOnClick={false}
          deleteKeyCode={null}
          zoomOnDoubleClick={false}
          edgesFocusable={false}
          edgesReconnectable={false}
          style={{ color: "black" }}
          onNodeClick={(event, node) =>
            setSelectedStep(node.data.stepNumber as number)
          }
        >
          <TrajectoryLegend />
          <TrajectoryStepDetails
            handleRestoreTrajectory={handleRestoreTrajectory}
            step={stepMap[selectedStep?.toString() ?? ""]}
            onCloseClick={() => setSelectedStep(undefined)}
          />
        </ReactFlow>
      </div>
    </BaseModal>
  );
}

export default TrajectoryBrowser;
