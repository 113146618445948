import ActionType from "#/types/ActionType";
import AgentState from "#/types/AgentState";
import Session from "./session";

const INIT_DELAY = 1000;

export function changeAgentState(
  state: AgentState,
  isMarkedIncorrect?: boolean,
): void {
  const eventPayload: {
    action: ActionType;
    args: { agent_state: AgentState; is_marked_incorrect?: boolean };
  } = {
    action: ActionType.CHANGE_AGENT_STATE,
    args: { agent_state: state },
  };

  if (isMarkedIncorrect !== undefined) {
    eventPayload.args.is_marked_incorrect = isMarkedIncorrect;
  }

  const eventString = JSON.stringify(eventPayload);
  Session.send(eventString);
  if (state === AgentState.STOPPED) {
    setTimeout(() => {
      Session.startNewSession();
    }, INIT_DELAY);
  }
}
