/* eslint-disable no-nested-ternary */
import { Spinner, Tooltip } from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { VscCheck, VscEdit, VscMenu } from "react-icons/vsc";
import { I18nKey } from "#/i18n/declaration";
import AgentState from "#/types/AgentState";
import { changeAgentState } from "#/services/agentStateService";
import {
  setActionDispatched,
  setShowEditResponseModal,
} from "#/state/chatSlice";
import { RootState } from "#/store";

interface ActionTooltipProps {
  type: "confirm" | "nudge" | "edit";
  onClick: () => void;
}

function ActionTooltip({ type, onClick }: ActionTooltipProps) {
  const { t } = useTranslation();

  const content =
    type === "confirm"
      ? t(I18nKey.CHAT_INTERFACE$USER_CONFIRMED)
      : type === "nudge"
        ? t(I18nKey.CHAT_INTERFACE$NEEDS_NUDGE)
        : t(I18nKey.CHAT_INTERFACE$NEEDS_EDIT);

  return (
    <Tooltip content={content} closeDelay={100}>
      <button
        data-testid={`action-${type}-button`}
        type="button"
        aria-label={type === "confirm" ? "Confirm action" : "Reject action"}
        className="bg-neutral-700 rounded-full p-1 hover:bg-neutral-800"
        onClick={onClick}
      >
        {type === "confirm" ? (
          <VscCheck />
        ) : type === "nudge" ? (
          <VscMenu />
        ) : (
          <VscEdit />
        )}
      </button>
    </Tooltip>
  );
}

function ConfirmationButtons() {
  const dispatch = useDispatch();
  const { actionDispatched } = useSelector((state: RootState) => state.chat);
  const [isMarkedIncorrect, setIsMarkedIncorrect] = useState(false);

  useEffect(
    () => () => {
      dispatch(setActionDispatched(false));
    },
    [dispatch],
  );

  return (
    <div className="flex justify-between items-center pt-4">
      <p> </p>
      {actionDispatched ? (
        <Spinner color="default" size="sm" className="p-2" />
      ) : (
        <div className="flex items-center gap-3">
          <label className="flex items-center cursor-pointer space-x-2">
            <input
              type="checkbox"
              data-testid="mark-incorrect"
              className="hidden"
              checked={isMarkedIncorrect}
              onChange={() => setIsMarkedIncorrect(!isMarkedIncorrect)}
            />
            <div
              className={`w-5 h-5 flex items-center justify-center rounded border border-neutral-300 transition ${
                isMarkedIncorrect ? "bg-neutral-700" : "bg-transparent"
              }`}
            >
              {isMarkedIncorrect && (
                <span className="text-white font-bold">✓</span>
              )}
            </div>
            <span className="text-sm font-medium text-neutral-300">
              Mark Incorrect
            </span>
          </label>
          <ActionTooltip
            type="confirm"
            onClick={() => {
              dispatch(setActionDispatched(true));
              changeAgentState(AgentState.USER_CONFIRMED, isMarkedIncorrect);
            }}
          />
          {/* <ActionTooltip
            type="nudge"
            onClick={() => changeAgentState(AgentState.NEEDS_NUDGE)}
          /> */}
          <ActionTooltip
            type="edit"
            onClick={() => dispatch(setShowEditResponseModal(true))}
          />
        </div>
      )}
    </div>
  );
}

export default ConfirmationButtons;
