import { Tab, Tabs } from "@nextui-org/react";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { IoMdChatbubbles } from "react-icons/io";
import { VscCode } from "react-icons/vsc";
import { RootState } from "#/store";
import { TabOption } from "#/types/TabOption";
import { I18nKey } from "#/i18n/declaration";
import ChatInterface from "./chat/ChatInterface";
import EditorInterface from "./chat/EditorInterface";

function Chatspace() {
  const { showEditResponseModal } = useSelector(
    (state: RootState) => state.chat,
  );

  const baseTabs = [TabOption.CHAT] as const;
  const extraTabs = showEditResponseModal ? ([TabOption.EDIT] as const) : [];
  const showTabs = [...baseTabs, ...extraTabs];

  const [activeTab, setActiveTab] = useState<(typeof showTabs)[number]>(
    TabOption.CHAT,
  );
  const { t } = useTranslation();

  const iconSize = 18;
  const tabData = useMemo(
    () => ({
      [TabOption.CHAT]: {
        name: "Logs",
        icon: <IoMdChatbubbles size={iconSize} />,
        component: <ChatInterface key="chat" />,
      },
      [TabOption.EDIT]: {
        name: t(I18nKey.WORKSPACE$EDIT_TAB_LABEL),
        icon: <VscCode size={iconSize} />,
        component: <EditorInterface key="edit" />,
      },
    }),
    [t],
  );

  useEffect(() => {
    if (showEditResponseModal) {
      setActiveTab(TabOption.EDIT);
    } else if (!showEditResponseModal) {
      setActiveTab(TabOption.CHAT);
    }
  }, [showEditResponseModal]);

  return (
    <div className="flex flex-col h-full grow">
      <div
        role="tablist"
        className="tabs tabs-bordered tabs-lg border-b border-neutral-600 flex"
      >
        <Tabs
          disableCursorAnimation
          classNames={{
            base: "w-full",
            tabList:
              "w-full relative rounded-none bg-neutral-900 p-0 gap-0 h-[36px] flex",
            tab: "rounded-none border-neutral-600 data-[selected=true]:bg-neutral-800 justify-start",
            tabContent: "group-data-[selected=true]:text-white",
          }}
          size="lg"
          selectedKey={activeTab}
          onSelectionChange={(v) => {
            setActiveTab(v as (typeof showTabs)[number]);
          }}
        >
          {showTabs.map((tab, index) => (
            <Tab
              key={tab}
              className={`flex-grow ${index + 1 === showTabs.length ? "" : "border-r"}`}
              title={
                <div className="flex grow items-center gap-2 justify-center text-xs">
                  {tabData[tab].icon}
                  <span>{tabData[tab].name}</span>
                </div>
              }
            />
          ))}
        </Tabs>
      </div>
      <div className="grow w-full bg-neutral-800 flex overflow-y-auto">
        {tabData[activeTab].component}
      </div>
    </div>
  );
}
export default Chatspace;
