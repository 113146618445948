export type TrajectoryJson = Record<string, TrajectoryStep[]>;

export type TrajectoryStep = {
  branch: number;
  command: string;
  command_type: string;
  confirmed: boolean;
  edited: boolean;
  observation: string;
  source: TrajectorySource;
  step: number;
  thought: string;
  user_justification: string | null;
  incorrect: boolean | null;
  uuid: string;
};

export enum TrajectorySource {
  User = "user",
  Agent = "agent",
}

export enum TrajectoryStatus {
  UserInput = "user_input",
  Accepted = "accepted",
  Rejected = "rejected",
  Resolved = "resolved",
  Failed = "failed",
  Errored = "errored",
}

export enum TrajectoryAction {
  Python = "run_ipython",
  Terminal = "run",
  Message = "message",
  Finish = "finish",
  Rejected = "reject",
  Error = "error",
}

export const TRAJECTORY_STATUS_TO_COLOR_MAP = {
  [TrajectoryStatus.Accepted]: "bg-green-300",
  [TrajectoryStatus.Rejected]: "bg-red-300",
  [TrajectoryStatus.Resolved]: "bg-blue-300",
  [TrajectoryStatus.UserInput]: "bg-gray-300",
  [TrajectoryStatus.Failed]: "bg-red-600",
  [TrajectoryStatus.Errored]: "bg-yellow-400",
};

// required because react-flow doesn't seem to work well with tailwind, if updating this then update the legend as well
export const TRAJECTORY_STATUS_TO_RGB_MAP = {
  [TrajectoryStatus.Accepted]: "rgb(134 239 172 / var(--tw-bg-opacity))",
  [TrajectoryStatus.Rejected]: "rgb(252 165 165 / var(--tw-bg-opacity))",
  [TrajectoryStatus.Resolved]: "rgb(147 197 253 / var(--tw-bg-opacity))",
  [TrajectoryStatus.UserInput]: "rgb(209 213 219 / var(--tw-bg-opacity))",
  [TrajectoryStatus.Failed]: "rgb(220 38 38 / var(--tw-bg-opacity))",
  [TrajectoryStatus.Errored]: "rgb(250 204 21 / var(--tw-bg-opacity))",
};
