import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  repoIsLoaded: false,
  showLoadRepoModal: false,
};

export const repoSlice = createSlice({
  name: "repo",
  initialState,
  reducers: {
    setRepoIsLoaded: (state, action) => {
      state.repoIsLoaded = action.payload;
    },
    setShowLoadRepoModal: (state, action) => {
      state.showLoadRepoModal = action.payload;
    },
  },
});

export const { setRepoIsLoaded, setShowLoadRepoModal } = repoSlice.actions;

export default repoSlice.reducer;
