import { Input, Textarea } from "@nextui-org/react";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import BaseModal from "../base-modal/BaseModal";
import { clone } from "#/services/commitService";
import { RootState } from "#/store";
import { setShowLoadRepoModal } from "#/state/repoSlice";

export function LoadRepoModal() {
  const { showLoadRepoModal } = useSelector((state: RootState) => state.repo);

  const dispatch = useDispatch();

  const [githubUrl, setGithubUrl] = useState("");
  const [issue, setIssue] = useState("");
  const [loading, setLoading] = useState(false);

  const handleLoadRepo = async () => {
    if (issue) {
      setLoading(true);
      clone(githubUrl, issue);

      setGithubUrl("");
      setIssue("");
      dispatch(setShowLoadRepoModal(false));
      setLoading(false);
    }
  };

  const handleLoadBlackjack = async () => {
    setLoading(true);
    const blackjackUrl = "https://github.com/edwin-pan/blackjack.git";

    clone(
      blackjackUrl,
      "When specifying num_games, running with -1 games should gracefully default to 100 games.",
    );
    setLoading(false);
  };

  return (
    <BaseModal
      isOpen={showLoadRepoModal}
      title="Load Repository"
      onOpenChange={() => {}}
      isDismissable={false}
      actions={[
        {
          label: "Import Blackjack",
          className: "bg-neutral-500 rounded-lg",
          action: handleLoadBlackjack,
          isDisabled: loading,
        },
        {
          label: "Load Repository",
          className: "bg-primary rounded-lg",
          action: handleLoadRepo,
          closeAfterAction: true,
          isDisabled: !issue || loading,
          isLoading: loading,
        },
        {
          label: "Cancel",
          className: "bg-rose-600 rounded-lg",
          action: () => dispatch(setShowLoadRepoModal(false)),
          closeAfterAction: true,
          isDisabled: loading,
        },
      ]}
    >
      <Input
        label="Github URL (Optional)"
        value={githubUrl}
        onChange={(e) => {
          setGithubUrl(e.target.value);
        }}
        isDisabled={loading}
      />
      <Textarea
        label="Problem Statement"
        value={issue}
        onChange={(e) => {
          setIssue(e.target.value);
        }}
        isDisabled={loading}
      />
    </BaseModal>
  );
}
