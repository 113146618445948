/* eslint-disable no-nested-ternary */
import { Spinner, Tooltip } from "@nextui-org/react";
import React, { useState } from "react";
import { VscGitCommit, VscRedo } from "react-icons/vsc";
import { useSelector } from "react-redux";
import { checkout } from "#/services/commitService";
import { RootState } from "#/store";
import "#/css/RevertButtons.css";
import toast from "#/utils/toast";

interface RevertTooltipProps {
  onClick: () => void;
}

function RevertTooltip({ onClick }: RevertTooltipProps) {
  const content = "revert";

  return (
    <Tooltip content={content} closeDelay={100}>
      <button
        data-testid="revert-button"
        type="button"
        aria-label="revert"
        className="bg-neutral-700 rounded-full p-1 hover:bg-neutral-800"
        onClick={onClick}
      >
        <VscRedo />
      </button>
    </Tooltip>
  );
}

interface RevertButtonsProps {
  commit_id: string;
  uuid: string;
}

function RevertButtons({ commit_id, uuid }: RevertButtonsProps) {
  const { revertDispatched, revertUUID } = useSelector(
    (state: RootState) => state.chat,
  );
  const [isDialogVisible, setDialogVisible] = useState(false);
  const [userJustification, setUserJustification] = useState("");

  const handleRevertClick = () => {
    setDialogVisible(true);
  };

  const handleConfirm = () => {
    if (userJustification.trim()) {
      checkout(commit_id, uuid, userJustification);
      setDialogVisible(false);
    } else {
      toast.error("input-error", "Please provide a justification.");
    }
  };

  return (
    <div className="flex justify-between items-center pt-4">
      {revertDispatched && revertUUID === uuid ? (
        <Spinner color="default" size="sm" className="p-2" />
      ) : revertDispatched ? (
        <div />
      ) : (
        <div className="flex items-center gap-2">
          <VscGitCommit />
          <span className="text-sm">{uuid.slice(0, 4)}</span>
          <RevertTooltip onClick={handleRevertClick} />
        </div>
      )}

      {isDialogVisible && (
        <div className="modal">
          <div
            className="modal-overlay"
            onClick={() => setDialogVisible(false)}
          />
          <div className="modal-content">
            <h3 className="modal-title">Confirm Revert</h3>
            <p>Please provide a justification for the revert:</p>
            <textarea
              className="modal-textarea"
              value={userJustification}
              onChange={(e) => setUserJustification(e.target.value)}
            />
            <div className="modal-actions">
              <button
                type="button"
                className="btn-cancel"
                onClick={() => setDialogVisible(false)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn-confirm"
                onClick={handleConfirm}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default RevertButtons;
