import { createSlice } from "@reduxjs/toolkit";

export type CodeAction = "findFile" | "findInFile" | "open" | "edit" | "other";

export interface FileState {
  path: string;
  savedContent: string;
  unsavedContent: string;
  selectedCodeAction?: CodeAction;
  selectedCodeActionFileName?: string;
  selectedCodeActionOtherCommandType?: string;
  selectedCodeActionOtherValue?: string;
  selectedCodeActionOriginalCode?: string;
  selectedCodeActionReplacementCode?: string;
}

export const initialState = {
  code: "",
  path: "",
  refreshID: 0,
  canEdit: false,
  editing: false,
  fileStates: [] as FileState[],
  selectedCodeAction: undefined as CodeAction | undefined,
  selectedCodeActionFileName: undefined as string | undefined,
  selectedCodeActionOtherCommandType: undefined as string | undefined,
  selectedCodeActionOtherValue: undefined as string | undefined,
  selectedCodeActionOriginalCode: undefined as string | undefined,
  selectedCodeActionReplacementCode: undefined as string | undefined,
};

export const codeSlice = createSlice({
  name: "code",
  initialState,
  reducers: {
    addOrUpdateFileState: (state, action) => {
      const { path, unsavedContent, savedContent } = action.payload;
      const newFileStates = state.fileStates.filter(
        (fileState) => fileState.path !== path,
      );
      newFileStates.push({ ...state, path, savedContent, unsavedContent });
      state.fileStates = newFileStates;
    },
    removeFileState: (state, action) => {
      const path = action.payload;
      state.fileStates = state.fileStates.filter(
        (fileState) => fileState.path !== path,
      );
    },
    resetCodeActions: (state) => ({
      ...state,
      editing: false,
      canEdit: false,
      selectedCodeAction: undefined,
      selectedCodeActionFileName: undefined,
      selectedCodeActionOtherCommandType: undefined,
      selectedCodeActionOtherValue: undefined,
      selectedCodeActionOriginalCode: undefined,
      selectedCodeActionReplacementCode: undefined,
    }),
    setActiveFilepath: (state, action) => {
      state.path = action.payload;
    },
    setCode: (state, action) => {
      state.code = action.payload;
    },
    setCanEdit: (state, action) => {
      state.canEdit = action.payload;
    },
    setEditing: (state, action) => {
      state.editing = action.payload;
    },
    setFileStates: (state, action) => {
      state.fileStates = action.payload;
    },
    setRefreshID: (state, action) => {
      state.refreshID = action.payload;
    },
    setSelectedCodeAction: (state, action) => {
      state.selectedCodeAction = action.payload;
    },
    setSelectedCodeActionFileName: (state, action) => {
      state.selectedCodeActionFileName = action.payload;
    },
    setSelectedCodeActionOtherCommandType: (state, action) => {
      state.selectedCodeActionOtherCommandType = action.payload;
    },
    setSelectedCodeActionOtherValue: (state, action) => {
      state.selectedCodeActionOtherValue = action.payload;
    },
    setSelectedCodeActionOriginalCode: (state, action) => {
      state.selectedCodeActionOriginalCode = action.payload;
    },
    setSelectedCodeActionReplacementCode: (state, action) => {
      state.selectedCodeActionReplacementCode = action.payload;
    },
  },
});

export const {
  addOrUpdateFileState,
  removeFileState,
  resetCodeActions,
  setActiveFilepath,
  setCode,
  setCanEdit,
  setEditing,
  setFileStates,
  setRefreshID,
  setSelectedCodeAction,
  setSelectedCodeActionFileName,
  setSelectedCodeActionOtherCommandType,
  setSelectedCodeActionOtherValue,
  setSelectedCodeActionOriginalCode,
  setSelectedCodeActionReplacementCode,
} = codeSlice.actions;

export default codeSlice.reducer;
