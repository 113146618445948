import { CodeAction } from "#/state/codeSlice";

type FindFileCommandProps = {
  value: string;
};

type FindInFileCommandProps = {
  fileFilter: string;
  value: string;
};

type OpenCommandProps = {
  filename: string;
};

type EditFileCommandProps = {
  filename: string;
  oldValue: string;
  newValue: string;
};

type OtherCommandProps = {
  actionType: "bash" | "python";
  value: string;
};

type CommandProps =
  | FindFileCommandProps
  | FindInFileCommandProps
  | OpenCommandProps
  | EditFileCommandProps
  | OtherCommandProps;

type Command = {
  action: string;
  commandType: "bash" | "python";
};

const buildFindFileCommand = (props: FindFileCommandProps): Command => ({
  action: `find . -type f -name "*${props.value}*"`,
  commandType: "bash",
});

const buildFindInFileCommand = (props: FindInFileCommandProps): Command => ({
  action: `find . -type f -name "${props.fileFilter}" | xargs grep -l "${props.value}"`,
  commandType: "bash",
});

const buildOpenCommand = (props: OpenCommandProps): Command => ({
  action: `open_file('${props.filename}')`,
  commandType: "python",
});

const buildEditFileCommand = (props: EditFileCommandProps): Command => ({
  action: `edit_file_by_replace('${props.filename}',\n  to_replace="""${props.oldValue}""",\n  new_content="""${props.newValue}""")`,
  commandType: "python",
});

const buildOtherCommand = (props: OtherCommandProps): Command => ({
  action: props.value,
  commandType: props.actionType,
});

export const buildEditorCommand = (
  codeAction: CodeAction,
  props: CommandProps,
): Command => {
  switch (codeAction) {
    case "findFile":
      return buildFindFileCommand(props as FindFileCommandProps);
    case "findInFile":
      return buildFindInFileCommand(props as FindInFileCommandProps);
    case "open":
      return buildOpenCommand(props as OpenCommandProps);
      break;
    case "edit":
      return buildEditFileCommand(props as EditFileCommandProps);
      break;
    case "other":
      return buildOtherCommand(props as OtherCommandProps);
    default:
      throw new Error("Invalid code action");
  }
};

export const validateDiffIndexes = (
  arr: string[],
  startIndex: number,
  endIndex: number,
): [number, number] => {
  let newStartIndex = startIndex;
  let newEndIndex = endIndex;

  if (startIndex === endIndex) {
    if (newStartIndex > 0) {
      newStartIndex -= 1;
    } else {
      newEndIndex += 1;
    }
  }

  while (newStartIndex > 0 && arr[newStartIndex] === "\n") {
    newStartIndex -= 1;
  }

  while (newEndIndex < arr.length - 1 && arr[newEndIndex] === "\n") {
    newEndIndex += 1;
  }

  return [newStartIndex, newEndIndex];
};
