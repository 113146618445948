import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import { ActionMessage, ObservationMessage } from "#/types/Message";

type SliceState = {
  messages: Message[];
  runCommands: RunCommand[];
  commits: Commit[];
  showEditResponseModal: boolean;
  showFinishTask: boolean;
  actionDispatched: boolean;
  revertDispatched: boolean;
  revertUUID: string;
};

const initialState: SliceState = {
  messages: [],
  runCommands: [],
  commits: [],
  showEditResponseModal: false,
  showFinishTask: false,
  actionDispatched: false,
  revertDispatched: false,
  revertUUID: "",
};

export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    addUserMessage(
      state,
      action: PayloadAction<{
        content: string;
        imageUrls: string[];
        timestamp: string;
      }>,
    ) {
      const message: Message = {
        uuid: uuidv4(),
        sender: "user",
        content: action.payload.content,
        imageUrls: action.payload.imageUrls,
        timestamp: action.payload.timestamp || new Date().toISOString(),
        commit: ".",
      };
      state.messages.push(message);
    },

    addAssistantMessage(
      state,
      action: PayloadAction<ActionMessage | ObservationMessage>,
    ) {
      if (!action.payload) {
        return;
      }
      const message: Message = {
        uuid: action.payload.uuid,
        sender: "assistant",
        content: action.payload.message,
        imageUrls: [],
        timestamp: new Date().toISOString(),
        commit: state.commits[state.commits.length - 1]?.hash,
        agentName: action.payload.source_agent,
        disabled: action.payload.disabled,
      };
      state.messages.push(message);
    },

    addEdit(
      state,
      action: PayloadAction<{ userMessage: string; uuid: string }>,
    ) {
      if (!action.payload) {
        return;
      }
      const message: Message = {
        uuid: action.payload.uuid,
        sender: "assistant",
        content: action.payload.userMessage,
        imageUrls: [],
        timestamp: new Date().toISOString(),
        commit: state.commits[state.commits.length - 1]?.hash,
      };
      state.messages.push(message);
    },

    addCodeOrCommandsUsed(
      state,
      action: PayloadAction<{ content: string; runType: "python" | "bash" }>,
    ) {
      const message: RunCommand = {
        sender: "assistant",
        runType: action.payload.runType,
        content: action.payload.content,
        timestamp: new Date().toISOString(),
      };
      state.runCommands.push(message);
    },

    clearMessages(state) {
      state.messages = [];
    },

    disablePreviousMessage(state) {
      if (state.messages[state.messages.length - 1]) {
        state.messages[state.messages.length - 1].disabled = true;
      }
    },

    commitStep(state, action) {
      state.commits.push({ hash: action.payload });
      if (state.messages[state.messages.length - 1]) {
        state.messages[state.messages.length - 1].commit = action.payload;
      }
    },

    setActionDispatched(state, action: PayloadAction<boolean>) {
      state.actionDispatched = action.payload;
    },

    setShowEditResponseModal(state, action: PayloadAction<boolean>) {
      state.showEditResponseModal = action.payload;
    },

    setShowFinishTask(state, action: PayloadAction<boolean>) {
      state.showFinishTask = action.payload;
    },

    removeMessagesAfterUUID(state) {
      const index = state.messages.findIndex(
        (message) => message.uuid === state.revertUUID,
      );
      if (index !== -1) {
        state.messages = state.messages.slice(0, index + 1);
      }
    },

    setRevertDispatched(state, action: PayloadAction<boolean>) {
      state.revertDispatched = action.payload;
    },

    setRevertUUID(state, action: PayloadAction<string>) {
      state.revertUUID = action.payload;
    },
  },
});

export const {
  addUserMessage,
  addAssistantMessage,
  addEdit,
  addCodeOrCommandsUsed,
  clearMessages,
  disablePreviousMessage,
  commitStep,
  setActionDispatched,
  setShowEditResponseModal,
  setShowFinishTask,
  removeMessagesAfterUUID,
  setRevertDispatched,
  setRevertUUID,
} = chatSlice.actions;
export default chatSlice.reducer;
