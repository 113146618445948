import React, { useRef } from "react";
import { Button } from "@nextui-org/react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { VscArrowDown, VscRobot } from "react-icons/vsc";
import { IoSettingsOutline } from "react-icons/io5";
import { CiUser } from "react-icons/ci";
import { RootState } from "#/store";
import { useScrollToBottom } from "#/hooks/useScrollToBottom";
import { I18nKey } from "#/i18n/declaration";
import { fetchMessageHistory } from "#/services/messageHistoryService";
import { setMessageHistory } from "#/state/messageHistorySlice";
import toast from "#/utils/toast";

function MessageHistory(): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { messageHistory } = useSelector(
    (state: RootState) => state.messageHistory,
  );

  const containerRef = useRef<HTMLDivElement>(null);

  const { hitBottom, scrollDomToBottom, onChatBodyScroll } =
    useScrollToBottom(containerRef);

  return (
    <div
      className="flex-1 overflow-y-auto h-full min-h-[100px] relative"
      ref={containerRef}
      onScroll={(e) => onChatBodyScroll(e.currentTarget)}
    >
      <div className="flex justify-end gap-2 sticky top-1 pr-1 ">
        <Button
          size="sm"
          onClick={async () => {
            navigator.clipboard.writeText(
              JSON.stringify(messageHistory, null, 2),
            );
            toast.info("Message history saved to clipboard");
          }}
        >
          Save History to Clipboard
        </Button>
        <Button
          size="sm"
          onClick={async () => {
            const messages = await fetchMessageHistory();
            dispatch(setMessageHistory(JSON.parse(messages)));
            toast.info("Message history refreshed");
          }}
        >
          Refresh Message History
        </Button>
      </div>
      {messageHistory.map((message, index) => (
        <div
          key={index}
          className="m-2 p-1 border rounded-md border-neutral-500 flex"
        >
          <div className="w-[80px] shrink-0 flex items-start justify-center pt-2">
            {message.role === "system" && <IoSettingsOutline size={20} />}
            {message.role === "user" && <CiUser size={20} />}
            {message.role === "assistant" && <VscRobot size={20} />}
          </div>
          <div className="whitespace-pre-wrap break-words p-2 border border-gray-600 rounded-md text-xs font-mono bg-neutral-700 text-gray-100 overflow-auto min-h-[100px] max-h-[300px]">
            {message.content}
          </div>
        </div>
      ))}
      {!hitBottom && (
        <div className="sticky bottom-2 flex items-center justify-center">
          <button
            type="button"
            className="relative border-1 text-sm rounded px-3 py-1 border-neutral-600 bg-neutral-700 cursor-pointer select-none"
          >
            <span className="flex items-center" onClick={scrollDomToBottom}>
              <VscArrowDown className="inline mr-2 w-3 h-3" />
              <span className="inline-block" onClick={scrollDomToBottom}>
                {t(I18nKey.CHAT_INTERFACE$TO_BOTTOM)}
              </span>
            </span>
          </button>
        </div>
      )}
    </div>
  );
}

export default MessageHistory;
