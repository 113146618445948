import React, { useMemo, useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Editor, { Monaco } from "@monaco-editor/react";
import { Tab, Tabs, Button } from "@nextui-org/react";
import {
  VscCode,
  VscSave,
  VscCheck,
  VscClose,
  VscEdit,
  VscPin,
} from "react-icons/vsc";
import type { editor } from "monaco-editor";
import { I18nKey } from "#/i18n/declaration";
import { RootState } from "#/store";
import FileExplorer from "./FileExplorer";
import {
  setCode,
  addOrUpdateFileState,
  FileState,
  setSelectedCodeActionFileName,
  setSelectedCodeActionOriginalCode,
  setSelectedCodeActionReplacementCode,
  setEditing,
  setCanEdit,
} from "#/state/codeSlice";

function CodeEditor(): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const fileStates = useSelector((state: RootState) => state.code.fileStates);
  const {
    canEdit,
    editing,
    path: activeFilepath,
    selectedCodeAction,
    selectedCodeActionFileName,
    selectedCodeActionOriginalCode,
  } = useSelector((state: RootState) => state.code);
  const selectedCodeFileState = fileStates.find(
    (f) => f.path === selectedCodeActionFileName,
  );
  const currentFileState = fileStates.find((f) => f.path === activeFilepath);

  const [showSaveNotification, setShowSaveNotification] = useState(false);
  const unsavedContent = currentFileState?.unsavedContent;
  const hasUnsavedChanges = currentFileState?.savedContent !== unsavedContent;

  const selectedFileName = useMemo(() => {
    const paths = activeFilepath.split("/");
    return paths[paths.length - 1];
  }, [activeFilepath]);

  useEffect(() => {
    if (!showSaveNotification) {
      return undefined;
    }
    const timeout = setTimeout(() => setShowSaveNotification(false), 2000);
    return () => clearTimeout(timeout);
  }, [showSaveNotification]);

  const handleEditorChange = useCallback(
    (value: string | undefined): void => {
      if (value !== undefined && canEdit) {
        dispatch(setCode(value));
        const newFileState = {
          path: activeFilepath,
          savedContent: selectedCodeFileState?.savedContent,
          unsavedContent: value,
        };
        dispatch(addOrUpdateFileState(newFileState));
      }
    },
    [canEdit, dispatch, activeFilepath, selectedCodeFileState?.savedContent],
  );

  const handleEditorDidMount = useCallback(
    (editor: editor.IStandaloneCodeEditor, monaco: Monaco): void => {
      monaco.editor.defineTheme("my-theme", {
        base: "vs-dark",
        inherit: true,
        rules: [],
        colors: {
          "editor.background": "#171717",
        },
      });

      monaco.editor.setTheme("my-theme");
    },
    [],
  );

  const handleEdit = useCallback(() => {
    dispatch(setCanEdit(true));
    dispatch(setEditing(true));
    if (!selectedCodeActionFileName) {
      dispatch(setSelectedCodeActionFileName(activeFilepath));
      dispatch(
        setSelectedCodeActionOriginalCode(currentFileState?.savedContent),
      );
      dispatch(
        setSelectedCodeActionReplacementCode(currentFileState?.savedContent),
      );
      return;
    }
    if (selectedCodeActionFileName !== activeFilepath) {
      const revertFileState = {
        path: selectedCodeActionFileName,
        savedContent: selectedCodeActionOriginalCode,
        unsavedContent: selectedCodeActionOriginalCode,
      };
      dispatch(addOrUpdateFileState(revertFileState));
      dispatch(setSelectedCodeActionFileName(activeFilepath));
      dispatch(
        setSelectedCodeActionOriginalCode(currentFileState?.savedContent),
      );
      dispatch(
        setSelectedCodeActionReplacementCode(currentFileState?.savedContent),
      );
    }
  }, [
    activeFilepath,
    currentFileState?.savedContent,
    dispatch,
    selectedCodeActionFileName,
    selectedCodeActionOriginalCode,
  ]);

  const handleSave = useCallback(async (): Promise<void> => {
    const newContent = selectedCodeFileState?.unsavedContent;
    if (newContent) {
      dispatch(setSelectedCodeActionReplacementCode(newContent));
    }
    dispatch(setCanEdit(false));
    dispatch(setEditing(false));
  }, [selectedCodeFileState?.unsavedContent, dispatch]);

  const handleCancel = useCallback(() => {
    const { path, savedContent } = selectedCodeFileState as FileState;
    dispatch(
      addOrUpdateFileState({
        path,
        savedContent,
        unsavedContent: savedContent,
      }),
    );
    dispatch(setEditing(false));
    dispatch(setCanEdit(false));
  }, [dispatch, selectedCodeFileState]);

  return (
    <div className="flex h-full w-full bg-neutral-900 transition-all duration-500 ease-in-out relative">
      <FileExplorer />
      <div className="flex flex-col min-h-0 w-full">
        <div className="flex justify-between items-center border-b border-neutral-600 mb-4">
          <Tabs
            disableCursorAnimation
            classNames={{
              base: "w-full",
              tabList:
                "w-full relative rounded-none bg-neutral-900 p-0 border-divider",
              cursor: "w-full bg-neutral-600 rounded-none",
              tab: "max-w-fit px-4 h-[36px]",
              tabContent: "group-data-[selected=true]:text-white",
            }}
            aria-label={t(I18nKey.CODE_EDITOR$OPTIONS)}
          >
            <Tab
              key={selectedFileName}
              title={selectedFileName || t(I18nKey.CODE_EDITOR$EMPTY_MESSAGE)}
            />
          </Tabs>
          <div className="flex items-center mr-2">
            {selectedCodeAction === "open" && (
              <Button
                className="text-white transition-colors duration-300"
                size="sm"
                startContent={<VscPin />}
                onClick={() => {
                  dispatch(setSelectedCodeActionFileName(activeFilepath));
                }}
                isDisabled={
                  !activeFilepath ||
                  activeFilepath === selectedCodeActionFileName
                }
              >
                Select
              </Button>
            )}
            {selectedCodeAction === "edit" && !editing && (
              <Button
                className="text-white transition-colors duration-300"
                size="sm"
                startContent={<VscEdit />}
                onClick={handleEdit}
                isDisabled={!selectedFileName}
              >
                Edit
              </Button>
            )}
            {canEdit && (
              <>
                <Button
                  onClick={handleCancel}
                  className="text-white transition-colors duration-300 mr-2"
                  size="sm"
                  startContent={<VscClose />}
                >
                  {t(I18nKey.FEEDBACK$CANCEL_LABEL)}
                </Button>
                <Button
                  onClick={handleSave}
                  className="bg-blue-600 text-white transition-colors duration-300 mr-2"
                  size="sm"
                  startContent={<VscSave />}
                  isDisabled={!hasUnsavedChanges}
                >
                  {t(I18nKey.CODE_EDITOR$SAVE_LABEL)}
                </Button>
              </>
            )}
          </div>
        </div>
        <div className="flex grow items-center justify-center">
          {!selectedFileName ? (
            <div className="flex flex-col items-center text-neutral-400">
              <VscCode size={100} />
              {t(I18nKey.CODE_EDITOR$EMPTY_MESSAGE)}
            </div>
          ) : (
            <Editor
              height="100%"
              path={selectedFileName.toLowerCase()}
              defaultValue=""
              value={unsavedContent}
              onMount={handleEditorDidMount}
              onChange={handleEditorChange}
              options={{
                readOnly:
                  !canEdit || selectedCodeActionFileName !== activeFilepath,
              }}
            />
          )}
        </div>
      </div>
      {showSaveNotification && (
        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2">
          <div className="bg-green-500 text-white px-4 py-2 rounded-lg flex items-center justify-center animate-pulse">
            <VscCheck className="mr-2 text-xl" />
            <span>{t(I18nKey.CODE_EDITOR$FILE_SAVED_SUCCESSFULLY)}</span>
          </div>
        </div>
      )}
    </div>
  );
}

export default CodeEditor;
