import React from "react";
import { Panel } from "@xyflow/react";
import { TrajectoryStatus, TRAJECTORY_STATUS_TO_COLOR_MAP } from "./types";

export function TrajectoryLegend() {
  return (
    <Panel position="bottom-right" className="bg-white border border-black">
      <div className="flex flex-col p-4">
        {Object.values(TrajectoryStatus).map((value) => (
          <div key={value} className="flex gap-2">
            <div
              className={`w-4 h-4 ${TRAJECTORY_STATUS_TO_COLOR_MAP[value]} my-auto`}
            />
            {value}
          </div>
        ))}
      </div>
    </Panel>
  );
}
