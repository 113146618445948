import React from "react";
import { Panel } from "@xyflow/react";

import "@xyflow/react/dist/style.css";
import { Textarea } from "@nextui-org/react";
import { VscClose } from "react-icons/vsc";
import { TRAJECTORY_STATUS_TO_COLOR_MAP, TrajectoryStep } from "./types";
import { getStatusForTrajectoryStep } from "./helper";

const renderField = (label: string, value: string | JSX.Element) => (
  <div className="flex gap-2 p-1">
    <div className="font-bold">{label}:</div>
    <div>{value}</div>
  </div>
);

const renderLargeField = (label: string, value: string) => (
  <div className="flex flex-col">
    <div className="font-bold text-white p-1">{label}:</div>
    <Textarea
      isReadOnly
      value={value}
      classNames={{
        inputWrapper:
          "bg-neutral-500 data-[hover=true]:bg-neutral-700 group-data-[focus=true]:bg-black",
      }}
      style={{ color: "white" }}
    />
  </div>
);

export function TrajectoryStepDetails({
  handleRestoreTrajectory,
  step,
  onCloseClick,
}: {
  handleRestoreTrajectory: (stepNumber: number) => void;
  step: TrajectoryStep | undefined;
  onCloseClick: () => void;
}) {
  if (!step) {
    return null;
  }

  const {
    step: stepNumber,
    command,
    observation,
    source,
    thought,
    user_justification: userJustification,
    incorrect,
  } = step;

  const stepStatus = getStatusForTrajectoryStep(step);

  return (
    <Panel
      position="top-right"
      className="bg-neutral-800 border border-black z-10 h-[95%] w-[35%] flex flex-col p-4 overflow-scroll"
    >
      <div className="flex justify-end w-full">
        <div className="cursor-pointer" onClick={onCloseClick}>
          <VscClose color="white" />
        </div>
      </div>
      <div className="flex flex-col p-4 overflow-scroll flex-grow text-white">
        {renderField("Step Number", stepNumber.toString())}
        {renderField(
          "Status",
          <div className="flex gap-2">
            <div
              className={`w-4 h-4 ${TRAJECTORY_STATUS_TO_COLOR_MAP[stepStatus]} my-auto`}
            />
            {stepStatus}
          </div>,
        )}
        {renderField("Source", source ?? "N/A")}
        {!command || command.length < 50
          ? renderField("Action", command ?? "N/A")
          : renderLargeField("Action", command)}
        {incorrect && (
          <div className="flex items-center gap-3 p-2 border-l-4 border-red-500 bg-neutral-700 text-white rounded-md shadow-md">
            <span className="text-red-500 text-lg">⚠️</span>
            <div className="flex items-center gap-2">
              <div className="font-bold">Note:</div>
              <span className="text-sm">This step was marked as incorrect</span>
            </div>
          </div>
        )}
        {thought && renderLargeField("Thought", thought)}
        {userJustification &&
          renderLargeField("Justification", userJustification)}
        {observation && renderLargeField("Result", observation)}
      </div>
      <button
        type="button"
        style={{
          padding: "0.5rem",
          backgroundColor: "white",
          borderRadius: ".5rem",
        }}
        onClick={() => handleRestoreTrajectory(stepNumber)}
      >
        Restore Session
      </button>
    </Panel>
  );
}
