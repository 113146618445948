import { Badge, Button } from "@nextui-org/react";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./store";
import AgentState from "./types/AgentState";
import { setShowLoadRepoModal } from "./state/repoSlice";
import {
  setShowTrajectoryBrowser,
  setTrajectoryNotification,
} from "./state/trajectorySlice";
import { getUser, submitTask } from "./services/scaleService";
import toast from "#/utils/toast";
import { useIsDevelopment } from "./hooks/useIsDevelopment";
import { clone } from "./services/commitService";

function AppHeader(): JSX.Element {
  const [username, setUsername] = useState("");
  const { repoIsLoaded } = useSelector((state: RootState) => state.repo);
  const { curAgentState } = useSelector((state: RootState) => state.agent);
  const problemInfo = useSelector((state: RootState) => state.problemInfo);
  const { trajectoryNotification } = useSelector(
    (state: RootState) => state.trajectory,
  );
  const isDevelopment = useIsDevelopment();

  const dispatch = useDispatch();

  useEffect(() => {
    // TODO: Move this to global state
    // This is just used as an indicator that the user is logged in
    (async function fetchUser() {
      const user = await getUser();
      if (user?.username) {
        setUsername(user.username);
      }
    })();
  }, []);

  useEffect(() => {
    // TODO: could also move this to global state?
    async function initProblem() {
      if (
        !repoIsLoaded &&
        curAgentState === AgentState.INIT &&
        problemInfo.initialized
      ) {
        await clone("", problemInfo.problemStatement, "");
      }
    }
    initProblem();
  }, [repoIsLoaded, curAgentState, problemInfo]);

  return (
    <div className="flex justify-between items-center">
      <div>
        {username && <div className="pl-4 font-bold">User ID: {username}</div>}
        {!username && <div className="pl-4 font-bold">Not logged in</div>}
        <div className="pl-4">Version: 2</div>
      </div>
      <div className="flex justify-end pt-2 px-2 gap-2">
        {isDevelopment && (
          <Button
            onClick={() => dispatch(setShowLoadRepoModal(true))}
            isDisabled={repoIsLoaded || curAgentState !== AgentState.INIT}
          >
            Import Issue
          </Button>
        )}
        <Badge
          isDot
          content={trajectoryNotification ? " " : undefined}
          color="primary"
          showOutline={trajectoryNotification}
        >
          <Button
            onClick={() => {
              dispatch(setTrajectoryNotification(false));
              dispatch(setShowTrajectoryBrowser(true));
            }}
          >
            View Trajectory
          </Button>
        </Badge>
        <Button
          isDisabled={!trajectoryNotification}
          onClick={async () => {
            try {
              await submitTask();
              toast.success("task-submission", "Task submitted successfully!");
            } catch (e) {
              toast.error("task-submission", `Error submitting task: ${e}`);
            }
          }}
        >
          Submit Task
        </Button>
      </div>
    </div>
  );
}

export default AppHeader;
