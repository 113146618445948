import { createSlice } from "@reduxjs/toolkit";
import { TrajectoryJson } from "#/components/modals/trajectory-browser/types";

export const initialState = {
  showTrajectoryBrowser: false,
  loadedTrajectory: undefined as TrajectoryJson | undefined,
  trajectoryNotification: false,
};

export const trajectorySlice = createSlice({
  name: "trajectory",
  initialState,
  reducers: {
    setLoadedTrajectory: (state, action) => {
      state.loadedTrajectory = action.payload;
    },
    setShowTrajectoryBrowser: (state, action) => {
      state.showTrajectoryBrowser = action.payload;
    },
    setTrajectoryNotification: (state, action) => {
      state.trajectoryNotification = action.payload;
    },
  },
});

export const {
  setLoadedTrajectory,
  setShowTrajectoryBrowser,
  setTrajectoryNotification,
} = trajectorySlice.actions;

export default trajectorySlice.reducer;
