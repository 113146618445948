import {
  TrajectoryStep,
  TrajectoryStatus,
  TrajectoryAction,
  TrajectorySource,
} from "./types";

export const getStatusForTrajectoryStep = (
  step: TrajectoryStep,
): TrajectoryStatus => {
  if (step.command_type === TrajectoryAction.Finish) {
    return TrajectoryStatus.Resolved;
  }
  if (step.command_type === TrajectoryAction.Rejected) {
    return TrajectoryStatus.Failed;
  }
  if (step.command_type === TrajectoryAction.Error) {
    return TrajectoryStatus.Errored;
  }
  if (
    step.command_type === TrajectoryAction.Message ||
    step.source === TrajectorySource.User
  ) {
    return TrajectoryStatus.UserInput;
  }
  if (step.confirmed) {
    return TrajectoryStatus.Accepted;
  }
  if (!step.confirmed) {
    return TrajectoryStatus.Rejected;
  }

  throw new Error(`Cannot determine status for step: ${JSON.stringify(step)}`);
};

export const getLabelForTrajectoryStep = (step: TrajectoryStep): string => {
  if (step.command_type === TrajectoryAction.Message) {
    return "user_input";
  }

  if (step.command_type === TrajectoryAction.Finish) {
    return "resolved";
  }

  if (step.command_type === TrajectoryAction.Rejected) {
    return "rejected";
  }

  if (step.command_type === TrajectoryAction.Error) {
    return "errored";
  }

  if (step.command) {
    const splitMessage = step.command.split("(");
    if (splitMessage.length > 1) {
      return splitMessage[0];
    }

    return step.command.split(" ")[0];
  }

  if (step.observation) {
    return step.observation;
  }

  throw new Error(`Cannot determine label for step: ${JSON.stringify(step)}`);
};
