import { Tab, Tabs } from "@nextui-org/react";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { VscCode, VscListOrdered, VscTerminal } from "react-icons/vsc";
import { useSelector } from "react-redux";
import { IoIosChatbubbles } from "react-icons/io";
import { IoInformationCircle } from "react-icons/io5";
import { I18nKey } from "#/i18n/declaration";
import { initialState as initialBrowserState } from "#/state/browserSlice";
import { initialState as initialCodeState } from "#/state/codeSlice";
import { RootState } from "#/store";
import { TabOption, TabType } from "#/types/TabOption";
import Planner from "./Planner";
import { getSettings } from "#/services/settings";
import MessageHistory from "./MessageHistory";
import CodeEditor from "./file-explorer/CodeEditor";
import ProblemInfo from "./ProblemInfo";
import Jupyter from "./Jupyter";
import Terminal from "./terminal/Terminal";

function Workspace() {
  const { t } = useTranslation();
  const task = useSelector((state: RootState) => state.task.task);
  const code = useSelector((state: RootState) => state.code.code);

  const { AGENT } = getSettings();
  const baseTabs = [
    TabOption.PROBLEM_INFO,
    TabOption.CODE,
    TabOption.MESSAGE_HISTORY,
    TabOption.TERMINAL,
    TabOption.JUPYTER,
  ] as const;
  const extraTabsMap = {
    PlannerAgent: [TabOption.PLANNER] as const,
  } as const;
  const extraTabs = extraTabsMap[AGENT as keyof typeof extraTabsMap] ?? [];
  const showTabs = [...baseTabs, ...extraTabs];

  const screenshotSrc = useSelector(
    (state: RootState) => state.browser.screenshotSrc,
  );
  const [activeTab, setActiveTab] = useState<TabType>(TabOption.PROBLEM_INFO);
  const [changes, setChanges] = useState<Partial<Record<TabType, boolean>>>({
    [TabOption.PLANNER]: false,
    [TabOption.MESSAGE_HISTORY]: false,
  });

  const iconSize = 18;
  const tabData = useMemo(
    () => ({
      [TabOption.PLANNER]: {
        name: t(I18nKey.WORKSPACE$PLANNER_TAB_LABEL),
        icon: <VscListOrdered size={iconSize} />,
        component: <Planner key="planner" />,
      },
      [TabOption.MESSAGE_HISTORY]: {
        name: t(I18nKey.WORKSPACE$MESSAGE_HISTORY_TAB_LABEL),
        icon: <IoIosChatbubbles size={iconSize} />,
        component: <MessageHistory key="messageHistory" />,
      },
      [TabOption.CODE]: {
        name: t(I18nKey.WORKSPACE$CODE_VIEWER_TAB_LABEL),
        icon: <VscCode size={iconSize} />,
        component: <CodeEditor key="code" />,
      },
      [TabOption.PROBLEM_INFO]: {
        name: t(I18nKey.WORKSPACE$PROBLEM_INFO_TAB_LABEL),
        icon: <IoInformationCircle size={iconSize} />,
        component: <ProblemInfo key="problem" />,
      },
      [TabOption.JUPYTER]: {
        name: t(I18nKey.WORKSPACE$JUPYTER_TAB_LABEL),
        icon: <VscCode size={iconSize} />,
        component: <Jupyter key="jupyter" />,
      },
      [TabOption.TERMINAL]: {
        name: t(I18nKey.WORKSPACE$TERMINAL_TAB_LABEL),
        icon: <VscTerminal size={iconSize} />,
        component: <Terminal key="terminal" />,
      },
    }),
    [t],
  );

  useEffect(() => {
    if (activeTab !== TabOption.PLANNER && task) {
      setChanges((prev) => ({ ...prev, [TabOption.PLANNER]: true }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task]);

  useEffect(() => {
    if (activeTab !== TabOption.CODE && code !== initialCodeState.code) {
      setChanges((prev) => ({ ...prev, [TabOption.CODE]: true }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task]);

  useEffect(() => {
    if (
      activeTab !== TabOption.BROWSER &&
      screenshotSrc !== initialBrowserState.screenshotSrc
    ) {
      setChanges((prev) => ({ ...prev, [TabOption.BROWSER]: true }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenshotSrc]);

  return (
    <div className="flex flex-col min-h-0 grow h-full">
      <div
        role="tablist"
        className="tabs tabs-bordered tabs-lg border-b border-neutral-600 flex"
      >
        <Tabs
          disableCursorAnimation
          classNames={{
            base: "w-full",
            tabList:
              "w-full relative rounded-none bg-neutral-900 p-0 gap-0 h-[36px] flex",
            tab: "rounded-none border-neutral-600 data-[selected=true]:bg-neutral-800 justify-start",
            tabContent: "group-data-[selected=true]:text-white",
          }}
          size="lg"
          onSelectionChange={(v) => {
            setChanges((prev) => ({ ...prev, [v as TabType]: false }));
            setActiveTab(v as TabType);
          }}
        >
          {showTabs.map((tab, index) => (
            <Tab
              key={tab}
              className={`flex-grow ${index + 1 === showTabs.length ? "" : "border-r"}`}
              title={
                <div className="flex grow items-center gap-2 justify-center text-xs">
                  {tabData[tab].icon}
                  <span>{tabData[tab].name}</span>
                  {changes[tab] && (
                    <div className="w-2 h-2 rounded-full animate-pulse bg-blue-500" />
                  )}
                </div>
              }
            />
          ))}
        </Tabs>
      </div>
      <div className="grow w-full bg-neutral-800 flex min-h-0">
        {tabData[activeTab as (typeof showTabs)[number]].component}
      </div>
    </div>
  );
}
export default Workspace;
