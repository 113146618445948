import { request } from "./api";
import { TrajectoryStep } from "#/components/modals/trajectory-browser/types";

export async function restoreTrajectory(
  trajectory: TrajectoryStep[],
): Promise<void> {
  await request("/api/restore-trajectory", {
    method: "POST",
    body: JSON.stringify({ trajectory }),
  });
}
