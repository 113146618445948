import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { TrajectoryStep } from "#/components/modals/trajectory-browser/types";
import { restoreTrajectory } from "#/services/trajectoryService";
import { addUserMessage, clearMessages } from "#/state/chatSlice";
import { ActionMessage } from "#/types/Message";
import ActionType from "#/types/ActionType";
import { handleActionMessage } from "#/services/actions";

export const useRestoreTrajectory = () => {
  const dispatch = useDispatch();
  const restoreToStep = useCallback(
    async (
      trajectoryToRestore: TrajectoryStep[],
      restoreContainerState: boolean,
    ) => {
      if (trajectoryToRestore.length === 0) {
        return;
      }

      dispatch(clearMessages());

      const orderedSteps = trajectoryToRestore.sort((a, b) =>
        a.step < b.step ? -1 : 1,
      );

      for (let i = 0; i < orderedSteps.length; i += 1) {
        const step = orderedSteps[i];
        if (step.source === "user") {
          dispatch(
            addUserMessage({
              content: step.thought,
              imageUrls: [],
              timestamp: new Date().toISOString(),
            }),
          );
        } else if (step.source === "agent") {
          const message: ActionMessage = {
            action:
              step.command_type === "run"
                ? ActionType.RUN
                : ActionType.RUN_IPYTHON,
            args: {
              thought: step.thought,
              [step.command_type === "run" ? "command" : "code"]: step.command,
              isConfirmed: !step.confirmed
                ? "awaiting_confirmation"
                : "confirmed",
            },
            timestamp: new Date().toISOString(),
            uuid: step.uuid,
            message: step.command,
            source: step.source,
            source_agent: "",
          };
          const disabled =
            i === orderedSteps.length - 1 ? false : !step.confirmed;
          handleActionMessage(message, disabled);
        }
      }

      if (restoreContainerState) {
        await restoreTrajectory(trajectoryToRestore);
      }
    },
    [dispatch],
  );

  return restoreToStep;
};
