// this file generate by script, don't modify it manually!!!
export enum I18nKey {
  WORKSPACE$TITLE = "WORKSPACE$TITLE",
  WORKSPACE$TERMINAL_TAB_LABEL = "WORKSPACE$TERMINAL_TAB_LABEL",
  CODE_EDITOR$FILE_SAVED_SUCCESSFULLY = "CODE_EDITOR$FILE_SAVED_SUCCESSFULLY",
  CODE_EDITOR$SAVING_LABEL = "CODE_EDITOR$SAVING_LABEL",
  CODE_EDITOR$SAVE_LABEL = "CODE_EDITOR$SAVE_LABEL",
  CODE_EDITOR$OPTIONS = "CODE_EDITOR$OPTIONS",
  CODE_EDITOR$FILE_SAVE_ERROR = "CODE_EDITOR$FILE_SAVE_ERROR",
  CODE_EDITOR$EMPTY_MESSAGE = "CODE_EDITOR$EMPTY_MESSAGE",
  FILE_SERVICE$SELECT_FILE_ERROR = "FILE_SERVICE$SELECT_FILE_ERROR",
  FILE_SERVICE$UPLOAD_FILES_ERROR = "FILE_SERVICE$UPLOAD_FILES_ERROR",
  FILE_SERVICE$LIST_FILES_ERROR = "FILE_SERVICE$LIST_FILES_ERROR",
  FILE_SERVICE$SAVE_FILE_ERROR = "FILE_SERVICE$SAVE_FILE_ERROR",
  FILE_SERVICE$INVALID_FILE_PATH = "FILE_SERVICE$INVALID_FILE_PATH",
  WORKSPACE$PLANNER_TAB_LABEL = "WORKSPACE$PLANNER_TAB_LABEL",
  WORKSPACE$CHAT_TAB_LABEL = "WORKSPACE$CHAT_TAB_LABEL",
  WORKSPACE$EDIT_TAB_LABEL = "WORKSPACE$EDIT_TAB_LABEL",
  WORKSPACE$JUPYTER_TAB_LABEL = "WORKSPACE$JUPYTER_TAB_LABEL",
  WORKSPACE$MESSAGE_HISTORY_TAB_LABEL = "WORKSPACE$MESSAGE_HISTORY_TAB_LABEL",
  WORKSPACE$CODE_VIEWER_TAB_LABEL = "WORKSPACE$CODE_VIEWER_TAB_LABEL",
  WORKSPACE$PROBLEM_INFO_TAB_LABEL = "WORKSPACE$PROBLEM_INFO_TAB_LABEL",
  WORKSPACE$BROWSER_TAB_LABEL = "WORKSPACE$BROWSER_TAB_LABEL",
  CONFIGURATION$OPENHANDS_WORKSPACE_DIRECTORY_INPUT_LABEL = "CONFIGURATION$OPENHANDS_WORKSPACE_DIRECTORY_INPUT_LABEL",
  CONFIGURATION$OPENHANDS_WORKSPACE_DIRECTORY_INPUT_PLACEHOLDER = "CONFIGURATION$OPENHANDS_WORKSPACE_DIRECTORY_INPUT_PLACEHOLDER",
  CONFIGURATION$MODAL_TITLE = "CONFIGURATION$MODAL_TITLE",
  CONFIGURATION$MODEL_SELECT_LABEL = "CONFIGURATION$MODEL_SELECT_LABEL",
  CONFIGURATION$MODEL_SELECT_PLACEHOLDER = "CONFIGURATION$MODEL_SELECT_PLACEHOLDER",
  CONFIGURATION$AGENT_SELECT_LABEL = "CONFIGURATION$AGENT_SELECT_LABEL",
  CONFIGURATION$AGENT_SELECT_PLACEHOLDER = "CONFIGURATION$AGENT_SELECT_PLACEHOLDER",
  CONFIGURATION$LANGUAGE_SELECT_LABEL = "CONFIGURATION$LANGUAGE_SELECT_LABEL",
  CONFIGURATION$LANGUAGE_SELECT_PLACEHOLDER = "CONFIGURATION$LANGUAGE_SELECT_PLACEHOLDER",
  CONFIGURATION$IMAGE_SELECT_LABEL = "CONFIGURATION$IMAGE_SELECT_LABEL",
  CONFIGURATION$IMAGE_SELECT_PLACEHOLDER = "CONFIGURATION$IMAGE_SELECT_PLACEHOLDER",
  CONFIGURATION$SECURITY_SELECT_LABEL = "CONFIGURATION$SECURITY_SELECT_LABEL",
  CONFIGURATION$SECURITY_SELECT_PLACEHOLDER = "CONFIGURATION$SECURITY_SELECT_PLACEHOLDER",
  CONFIGURATION$MODAL_CLOSE_BUTTON_LABEL = "CONFIGURATION$MODAL_CLOSE_BUTTON_LABEL",
  CONFIGURATION$MODAL_SAVE_BUTTON_LABEL = "CONFIGURATION$MODAL_SAVE_BUTTON_LABEL",
  CONFIGURATION$MODAL_RESET_BUTTON_LABEL = "CONFIGURATION$MODAL_RESET_BUTTON_LABEL",
  CONFIGURATION$SETTINGS_NEED_UPDATE_MESSAGE = "CONFIGURATION$SETTINGS_NEED_UPDATE_MESSAGE",
  CONFIGURATION$AGENT_LOADING = "CONFIGURATION$AGENT_LOADING",
  CONFIGURATION$AGENT_RUNNING = "CONFIGURATION$AGENT_RUNNING",
  CONFIGURATION$ERROR_FETCH_MODELS = "CONFIGURATION$ERROR_FETCH_MODELS",
  SESSION$SERVER_CONNECTED_MESSAGE = "SESSION$SERVER_CONNECTED_MESSAGE",
  SESSION$SESSION_HANDLING_ERROR_MESSAGE = "SESSION$SESSION_HANDLING_ERROR_MESSAGE",
  SESSION$SESSION_CONNECTION_ERROR_MESSAGE = "SESSION$SESSION_CONNECTION_ERROR_MESSAGE",
  SESSION$SOCKET_NOT_INITIALIZED_ERROR_MESSAGE = "SESSION$SOCKET_NOT_INITIALIZED_ERROR_MESSAGE",
  EXPLORER$UPLOAD_ERROR_MESSAGE = "EXPLORER$UPLOAD_ERROR_MESSAGE",
  EXPLORER$LABEL_DROP_FILES = "EXPLORER$LABEL_DROP_FILES",
  EXPLORER$LABEL_WORKSPACE = "EXPLORER$LABEL_WORKSPACE",
  EXPLORER$EMPTY_WORKSPACE_MESSAGE = "EXPLORER$EMPTY_WORKSPACE_MESSAGE",
  EXPLORER$LOADING_WORKSPACE_MESSAGE = "EXPLORER$LOADING_WORKSPACE_MESSAGE",
  EXPLORER$REFRESH_ERROR_MESSAGE = "EXPLORER$REFRESH_ERROR_MESSAGE",
  EXPLORER$UPLOAD_SUCCESS_MESSAGE = "EXPLORER$UPLOAD_SUCCESS_MESSAGE",
  EXPLORER$NO_FILES_UPLOADED_MESSAGE = "EXPLORER$NO_FILES_UPLOADED_MESSAGE",
  EXPLORER$UPLOAD_PARTIAL_SUCCESS_MESSAGE = "EXPLORER$UPLOAD_PARTIAL_SUCCESS_MESSAGE",
  EXPLORER$UPLOAD_UNEXPECTED_RESPONSE_MESSAGE = "EXPLORER$UPLOAD_UNEXPECTED_RESPONSE_MESSAGE",
  LOAD_SESSION$MODAL_TITLE = "LOAD_SESSION$MODAL_TITLE",
  LOAD_SESSION$MODAL_CONTENT = "LOAD_SESSION$MODAL_CONTENT",
  LOAD_SESSION$RESUME_SESSION_MODAL_ACTION_LABEL = "LOAD_SESSION$RESUME_SESSION_MODAL_ACTION_LABEL",
  LOAD_SESSION$START_NEW_SESSION_MODAL_ACTION_LABEL = "LOAD_SESSION$START_NEW_SESSION_MODAL_ACTION_LABEL",
  FEEDBACK$MODAL_TITLE = "FEEDBACK$MODAL_TITLE",
  FEEDBACK$MODAL_CONTENT = "FEEDBACK$MODAL_CONTENT",
  FEEDBACK$EMAIL_LABEL = "FEEDBACK$EMAIL_LABEL",
  FEEDBACK$CONTRIBUTE_LABEL = "FEEDBACK$CONTRIBUTE_LABEL",
  FEEDBACK$SHARE_LABEL = "FEEDBACK$SHARE_LABEL",
  FEEDBACK$CANCEL_LABEL = "FEEDBACK$CANCEL_LABEL",
  FEEDBACK$EMAIL_PLACEHOLDER = "FEEDBACK$EMAIL_PLACEHOLDER",
  CHAT_INTERFACE$INITIALIZING_AGENT_LOADING_MESSAGE = "CHAT_INTERFACE$INITIALIZING_AGENT_LOADING_MESSAGE",
  CHAT_INTERFACE$AGENT_INIT_MESSAGE = "CHAT_INTERFACE$AGENT_INIT_MESSAGE",
  CHAT_INTERFACE$AGENT_RUNNING_MESSAGE = "CHAT_INTERFACE$AGENT_RUNNING_MESSAGE",
  CHAT_INTERFACE$AGENT_AWAITING_USER_INPUT_MESSAGE = "CHAT_INTERFACE$AGENT_AWAITING_USER_INPUT_MESSAGE",
  CHAT_INTERFACE$AGENT_PAUSED_MESSAGE = "CHAT_INTERFACE$AGENT_PAUSED_MESSAGE",
  CHAT_INTERFACE$AGENT_STOPPED_MESSAGE = "CHAT_INTERFACE$AGENT_STOPPED_MESSAGE",
  CHAT_INTERFACE$AGENT_FINISHED_MESSAGE = "CHAT_INTERFACE$AGENT_FINISHED_MESSAGE",
  CHAT_INTERFACE$AGENT_REJECTED_MESSAGE = "CHAT_INTERFACE$AGENT_REJECTED_MESSAGE",
  CHAT_INTERFACE$AGENT_ERROR_MESSAGE = "CHAT_INTERFACE$AGENT_ERROR_MESSAGE",
  CHAT_INTERFACE$AGENT_AWAITING_USER_CONFIRMATION_MESSAGE = "CHAT_INTERFACE$AGENT_AWAITING_USER_CONFIRMATION_MESSAGE",
  CHAT_INTERFACE$AGENT_AWAITING_USER_REJECTION_MESSAGE = "CHAT_INTERFACE$AGENT_AWAITING_USER_REJECTION_MESSAGE",
  CHAT_INTERFACE$AGENT_ACTION_USER_CONFIRMED_MESSAGE = "CHAT_INTERFACE$AGENT_ACTION_USER_CONFIRMED_MESSAGE",
  CHAT_INTERFACE$AGENT_ACTION_USER_REJECTED_MESSAGE = "CHAT_INTERFACE$AGENT_ACTION_USER_REJECTED_MESSAGE",
  CHAT_INTERFACE$NEEDS_NUDGE = "CHAT_INTERFACE$NEEDS_NUDGE",
  CHAT_INTERFACE$NEEDS_EDIT = "CHAT_INTERFACE$NEEDS_EDIT",
  CHAT_INTERFACE$INPUT_PLACEHOLDER = "CHAT_INTERFACE$INPUT_PLACEHOLDER",
  CHAT_INTERFACE$INPUT_CONTINUE_MESSAGE = "CHAT_INTERFACE$INPUT_CONTINUE_MESSAGE",
  CHAT_INTERFACE$INPUT_NUDGE_MESSAGE = "CHAT_INTERFACE$INPUT_NUDGE_MESSAGE",
  CHAT_INTERFACE$INPUT_EDIT_MESSAGE = "CHAT_INTERFACE$INPUT_EDIT_MESSAGE",
  CHAT_INTERFACE$USER_ASK_CONFIRMATION = "CHAT_INTERFACE$USER_ASK_CONFIRMATION",
  CHAT_INTERFACE$USER_ASK_REJECTION = "CHAT_INTERFACE$USER_ASK_REJECTION",
  CHAT_INTERFACE$USER_CONFIRMED = "CHAT_INTERFACE$USER_CONFIRMED",
  CHAT_INTERFACE$USER_REJECTED = "CHAT_INTERFACE$USER_REJECTED",
  CHAT_INTERFACE$INPUT_SEND_MESSAGE_BUTTON_CONTENT = "CHAT_INTERFACE$INPUT_SEND_MESSAGE_BUTTON_CONTENT",
  CHAT_INTERFACE$CHAT_MESSAGE_COPIED = "CHAT_INTERFACE$CHAT_MESSAGE_COPIED",
  CHAT_INTERFACE$CHAT_MESSAGE_COPY_FAILED = "CHAT_INTERFACE$CHAT_MESSAGE_COPY_FAILED",
  CHAT_INTERFACE$TOOLTIP_COPY_MESSAGE = "CHAT_INTERFACE$TOOLTIP_COPY_MESSAGE",
  CHAT_INTERFACE$TOOLTIP_SEND_MESSAGE = "CHAT_INTERFACE$TOOLTIP_SEND_MESSAGE",
  CHAT_INTERFACE$TOOLTIP_UPLOAD_IMAGE = "CHAT_INTERFACE$TOOLTIP_UPLOAD_IMAGE",
  CHAT_INTERFACE$INITIAL_MESSAGE = "CHAT_INTERFACE$INITIAL_MESSAGE",
  CHAT_INTERFACE$ASSISTANT = "CHAT_INTERFACE$ASSISTANT",
  CHAT_INTERFACE$TO_BOTTOM = "CHAT_INTERFACE$TO_BOTTOM",
  CHAT_INTERFACE$MESSAGE_ARIA_LABEL = "CHAT_INTERFACE$MESSAGE_ARIA_LABEL",
  CHAT_INTERFACE$CHAT_CONVERSATION = "CHAT_INTERFACE$CHAT_CONVERSATION",
  CHAT_INTERFACE$UNKNOWN_SENDER = "CHAT_INTERFACE$UNKNOWN_SENDER",
  SECURITY_ANALYZER$UNKNOWN_RISK = "SECURITY_ANALYZER$UNKNOWN_RISK",
  SECURITY_ANALYZER$LOW_RISK = "SECURITY_ANALYZER$LOW_RISK",
  SECURITY_ANALYZER$MEDIUM_RISK = "SECURITY_ANALYZER$MEDIUM_RISK",
  SECURITY_ANALYZER$HIGH_RISK = "SECURITY_ANALYZER$HIGH_RISK",
  SETTINGS$MODEL_TOOLTIP = "SETTINGS$MODEL_TOOLTIP",
  SETTINGS$AGENT_TOOLTIP = "SETTINGS$AGENT_TOOLTIP",
  SETTINGS$LANGUAGE_TOOLTIP = "SETTINGS$LANGUAGE_TOOLTIP",
  SETTINGS$DISABLED_RUNNING = "SETTINGS$DISABLED_RUNNING",
  SETTINGS$API_KEY_PLACEHOLDER = "SETTINGS$API_KEY_PLACEHOLDER",
  SETTINGS$CONFIRMATION_MODE = "SETTINGS$CONFIRMATION_MODE",
  SETTINGS$CONFIRMATION_MODE_TOOLTIP = "SETTINGS$CONFIRMATION_MODE_TOOLTIP",
  SETTINGS$AGENT_SELECT_ENABLED = "SETTINGS$AGENT_SELECT_ENABLED",
  SETTINGS$SECURITY_ANALYZER = "SETTINGS$SECURITY_ANALYZER",
  BROWSER$EMPTY_MESSAGE = "BROWSER$EMPTY_MESSAGE",
  PLANNER$EMPTY_MESSAGE = "PLANNER$EMPTY_MESSAGE",
  FEEDBACK$PUBLIC_LABEL = "FEEDBACK$PUBLIC_LABEL",
  FEEDBACK$PRIVATE_LABEL = "FEEDBACK$PRIVATE_LABEL",
  STATUS$STARTING_RUNTIME = "STATUS$STARTING_RUNTIME",
  STATUS$STARTING_CONTAINER = "STATUS$STARTING_CONTAINER",
  STATUS$PREPARING_CONTAINER = "STATUS$PREPARING_CONTAINER",
  STATUS$CONTAINER_STARTED = "STATUS$CONTAINER_STARTED",
  STATUS$WAITING_FOR_CLIENT = "STATUS$WAITING_FOR_CLIENT",
}
