import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "#/store";
import { useTerminal } from "../../hooks/useTerminal";
import "@xterm/xterm/css/xterm.css";

function Terminal() {
  const { commands } = useSelector((state: RootState) => state.cmd);
  const ref = useTerminal(commands);

  return <div ref={ref} className="flex-1 h-full overflow-y-hidden p-2 " />;
}

export default Terminal;
