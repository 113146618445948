import { createSlice } from "@reduxjs/toolkit";

export type Message = {
  role: string;
  content: string;
};

const initialCells: Message[] = [];

export const messageHistorySlice = createSlice({
  name: "messageHistory",
  initialState: {
    messageHistory: initialCells,
  },
  reducers: {
    setMessageHistory: (state, action) => {
      state.messageHistory = action.payload;
    },
  },
});

export const { setMessageHistory } = messageHistorySlice.actions;

export default messageHistorySlice.reducer;
