import ActionType from "#/types/ActionType";
import Session from "./session";

export function sendChatMessage(
  message: string,
  images_urls: string[],
  timestamp: string,
  thought?: string,
  actionType?: string,
  uuid?: string,
): void {
  const event = {
    action: ActionType.MESSAGE,
    args: {
      content: message,
      images_urls,
      timestamp,
      thought,
      actionType,
      message_uuid: uuid,
    },
  };
  const eventString = JSON.stringify(event);
  Session.send(eventString);
}
