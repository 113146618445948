import { request } from "./api";

export type ProblemInfoDataType = {
  uid: string;
  prURL: string;
  issueURL: string;
  problemStatement: string;
  goldenPatch: string;
};

export async function getProblemInfo(): Promise<ProblemInfoDataType> {
  const url = `/api/problemInfo`;
  const data = await request(url);
  const problemInfo: ProblemInfoDataType = {
    uid: data.uid.toUpperCase(),
    prURL: data.pr_url,
    issueURL: data.issue_url,
    problemStatement: data.problem_statement,
    goldenPatch: data.golden_patch,
  };
  return problemInfo;
}
