import React, { useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactMarkdown from "react-markdown";
import { useScrollToBottom } from "#/hooks/useScrollToBottom";
import { RootState } from "#/store";
import { getProblemInfo } from "#/services/problemInfoService";
import { setProblemInfoState } from "#/state/problemInfoSlice";

function ProblemInfo(): JSX.Element {
  const containerRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const { onChatBodyScroll } = useScrollToBottom(containerRef);
  const { uid, prURL, issueURL, problemStatement, goldenPatch, initialized } =
    useSelector((state: RootState) => state.problemInfo);

  useEffect(() => {
    const fetchProblemInfo = async () => {
      if (!initialized) {
        const newProblemInfo = await getProblemInfo();
        dispatch(setProblemInfoState(newProblemInfo));
      }
    };
    fetchProblemInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialized]);

  return (
    <div
      ref={containerRef}
      onScroll={(e) => onChatBodyScroll(e.currentTarget)}
      className="w-full p-4 flex-1 overflow-y-auto h-full"
    >
      <div>
        <div className="text-2xl font-bold">{uid}</div>
        <div className="text-xs font-bold">
          <a
            href={prURL}
            target="_blank"
            rel="noopener noreferrer"
            className="underline text-blue-500"
          >
            Pull Request
          </a>
          <span className="px-2">·</span>
          <a
            href={issueURL}
            target="_blank"
            rel="noopener noreferrer"
            className="underline text-blue-500"
          >
            Linked Issue
          </a>
        </div>
      </div>
      <div className="px-5 py-2">
        <div className="text-xl font-bold py-2">Problem Statement</div>
        <div className="whitespace-pre-wrap break-words p-2 rounded-md text-xs font-mono bg-neutral-700 text-gray-100 w-full">
          <ReactMarkdown>{problemStatement}</ReactMarkdown>
        </div>
        <div className="text-xl font-bold py-2">Golden Patch</div>
        <pre className="whitespace-pre-wrap break-words p-2 rounded-md text-xs font-mono bg-neutral-700 text-gray-100 w-full">
          <code>{goldenPatch}</code>
        </pre>
      </div>
    </div>
  );
}

export default ProblemInfo;
