import { combineReducers, configureStore } from "@reduxjs/toolkit";
import agentReducer from "./state/agentSlice";
import browserReducer from "./state/browserSlice";
import chatReducer from "./state/chatSlice";
import codeReducer from "./state/codeSlice";
import commandReducer from "./state/commandSlice";
import errorsReducer from "./state/errorsSlice";
import taskReducer from "./state/taskSlice";
import jupyterReducer from "./state/jupyterSlice";
import statusReducer from "./state/statusSlice";
import repoReducer from "./state/repoSlice";
import trajectoryReducer from "./state/trajectorySlice";
import messageHistoryReducer from "./state/messageHistorySlice";
import problemInfoReducer from "./state/problemInfoSlice";

export const rootReducer = combineReducers({
  agent: agentReducer,
  browser: browserReducer,
  chat: chatReducer,
  cmd: commandReducer,
  code: codeReducer,
  errors: errorsReducer,
  jupyter: jupyterReducer,
  repo: repoReducer,
  status: statusReducer,
  task: taskReducer,
  trajectory: trajectoryReducer,
  messageHistory: messageHistoryReducer,
  problemInfo: problemInfoReducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppStore = typeof store;
export type AppDispatch = typeof store.dispatch;

export default store;
