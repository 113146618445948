import { request } from "./api";

export async function submitTask() {
  const url = "/api/completion";
  const response = await request(url, {
    method: "POST",
  });
  return response;
}

export async function getUser() {
  const url = "/api/user";
  const response = await request(url);
  return response;
}
