import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { VscClose, VscSend } from "react-icons/vsc";
import { Button, Input, Select, SelectItem, Textarea } from "@nextui-org/react";
import { RootState } from "#/store";
import AgentState from "#/types/AgentState";
import {
  setActionDispatched,
  setShowEditResponseModal,
} from "#/state/chatSlice";
import { changeAgentState } from "#/services/agentStateService";
import {
  addOrUpdateFileState,
  CodeAction,
  resetCodeActions,
  setSelectedCodeAction,
} from "#/state/codeSlice";
import {
  buildEditorCommand,
  validateDiffIndexes,
} from "./editorCommandHelpers";
import { edit } from "#/services/editService";

const SURROUNDING_CONTEXT_LINES = 3;

function EditorInterface() {
  const [thought, setThought] = useState("");
  const [userJustification, setUserJustification] = useState("");
  const [loading, setLoading] = useState(false);

  // find
  const [findFileFilter, setFindFileFilter] = useState("");
  const [findValue, setFindValue] = useState("");

  // other
  const [otherCommandType, setOtherCommandType] = useState("");
  const [otherValue, setOtherValue] = useState("");

  const { messages } = useSelector((state: RootState) => state.chat);

  // const { content: originalAction, runType: originalCommandType } =
  //   runCommands[runCommands.length - 1] ?? {};
  const { content: originalThought } = messages[messages.length - 1] ?? {};

  const resetResponse = useCallback(() => {
    const messageParts = originalThought.split("\n\n\n");

    const thoughtOnly =
      messageParts.length === 2
        ? messageParts[0].replace("Thought \n", "").trim()
        : "NO THOUGHT PROVIDED";

    setThought(thoughtOnly);
  }, [originalThought]);

  useEffect(() => {
    resetResponse();
  }, [resetResponse]);

  const {
    editing,
    selectedCodeAction,
    selectedCodeActionFileName,
    selectedCodeActionOriginalCode,
    selectedCodeActionReplacementCode,
  } = useSelector((state: RootState) => state.code);

  const fileStates = useSelector((state: RootState) => state.code.fileStates);
  const selectedCodeFileState = fileStates.find(
    (f) => f.path === selectedCodeActionFileName,
  );

  const dispatch = useDispatch();

  const [origCode, newCode] = useMemo(() => {
    if (
      selectedCodeAction !== "edit" ||
      !selectedCodeActionOriginalCode ||
      !selectedCodeActionReplacementCode
    ) {
      return ["", ""];
    }

    if (selectedCodeActionOriginalCode === selectedCodeActionReplacementCode) {
      return ["", ""];
    }

    const origArray = selectedCodeActionOriginalCode.split("\n");
    const newArray = selectedCodeActionReplacementCode.split("\n");

    let mismatchStartIndex = 0;

    for (let i = 0; i < origArray.length; i += 1) {
      if (origArray[i] !== newArray[i]) {
        mismatchStartIndex = i;
        break;
      }
    }

    let mismatchOrigIndex = mismatchStartIndex;
    let mismatchNewIndex = newArray.length - 1;

    for (let i = origArray.length - 1; i > mismatchStartIndex; i -= 1) {
      if (origArray[i] !== newArray[mismatchNewIndex]) {
        mismatchOrigIndex = i + 1;
        mismatchNewIndex += 1;
        break;
      }
      mismatchNewIndex -= 1;
      if (mismatchNewIndex < 0) {
        break;
      }
    }

    const [validatedOrigStartIndex, validatedOrigEndIndex] =
      validateDiffIndexes(origArray, mismatchStartIndex, mismatchOrigIndex);

    const [validatedNewStartIndex, validatedNewEndIndex] = validateDiffIndexes(
      newArray,
      validatedOrigStartIndex,
      mismatchNewIndex,
    );

    const contextStartIndex = Math.max(
      validatedOrigStartIndex - SURROUNDING_CONTEXT_LINES,
      0,
    );
    const contextEndIndex = Math.min(
      validatedOrigEndIndex + SURROUNDING_CONTEXT_LINES,
      origArray.length - 1,
    );

    const contextStartLines = origArray.slice(
      contextStartIndex,
      validatedOrigStartIndex,
    );
    const contextEndLine = origArray.slice(
      validatedOrigEndIndex + 1,
      contextEndIndex + 1,
    );

    return [
      [
        ...contextStartLines,
        ...origArray.slice(validatedOrigStartIndex, validatedOrigEndIndex + 1),
        ...contextEndLine,
      ].join("\n"),
      [
        ...contextStartLines,
        ...newArray.slice(validatedNewStartIndex, validatedNewEndIndex + 1),
        ...contextEndLine,
      ].join("\n"),
    ];
  }, [
    selectedCodeAction,
    selectedCodeActionOriginalCode,
    selectedCodeActionReplacementCode,
  ]);

  const buildCodeActionProps = useCallback(
    (codeAction: CodeAction | undefined) => {
      if (!codeAction) {
        return {};
      }
      switch (codeAction) {
        case "findFile":
          return { value: findValue };
        case "findInFile":
          return { fileFilter: findFileFilter, value: findValue };
        case "open":
          return { filename: selectedCodeActionFileName };
        case "edit":
          return {
            filename: selectedCodeActionFileName,
            oldValue: origCode,
            newValue: newCode,
          };
        case "other":
          return { actionType: otherCommandType, value: otherValue };
        default:
          return {};
      }
    },
    [
      findValue,
      findFileFilter,
      selectedCodeActionFileName,
      origCode,
      newCode,
      otherCommandType,
      otherValue,
    ],
  );

  const handleSendMessage = useCallback(async () => {
    setLoading(true);
    if (!selectedCodeAction) {
      return;
    }
    const { commandType, action } = buildEditorCommand(
      selectedCodeAction,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      buildCodeActionProps(selectedCodeAction) as any,
    );
    dispatch(setActionDispatched(true));
    changeAgentState(AgentState.AWAITING_USER_EDIT);
    if (selectedCodeAction === "edit" && selectedCodeFileState) {
      const savedFileState = {
        path: selectedCodeActionFileName,
        savedContent: selectedCodeFileState.unsavedContent,
        unsavedContent: selectedCodeFileState.unsavedContent,
      };

      dispatch(addOrUpdateFileState(savedFileState));
    }
    await edit(thought, userJustification, commandType, action);
    dispatch(resetCodeActions());
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    buildCodeActionProps,
    dispatch,
    selectedCodeAction,
    selectedCodeActionFileName,
    selectedCodeFileState,
    thought,
  ]);

  return (
    <div className="flex flex-col w-full h-full bg-neutral-800">
      <div className="flex flex-col relative flex-grow">
        <Textarea
          label="Thought"
          value={thought}
          onChange={(e) => {
            setThought(e.target.value);
          }}
          isDisabled={loading}
          className="p-2"
          classNames={{ inputWrapper: "bg-default-200" }}
        />
        <Textarea
          label="Justification (Required)"
          value={userJustification}
          onChange={(e) => {
            setUserJustification(e.target.value);
          }}
          isDisabled={loading}
          className="p-2"
          classNames={{ inputWrapper: "bg-default-200" }}
        />
        <Select
          label="Action Type"
          selectedKeys={selectedCodeAction ? [selectedCodeAction] : ""}
          value={selectedCodeAction}
          onChange={(e) => dispatch(setSelectedCodeAction(e.target.value))}
          aria-label="Select action type"
          className="p-2"
          classNames={{
            trigger: "bg-default-200",
            helperWrapper: "bg-default-200",
          }}
        >
          <SelectItem key="findFile" aria-label="findFile">
            Find File
          </SelectItem>
          <SelectItem key="findInFile" aria-label="findInFile">
            Find in File
          </SelectItem>
          <SelectItem key="open" aria-label="open">
            Open File
          </SelectItem>
          <SelectItem key="edit" aria-label="edit">
            Edit File
          </SelectItem>
          <SelectItem key="other" aria-label="other">
            Other
          </SelectItem>
        </Select>
        {selectedCodeAction === "findInFile" && (
          <Input
            label="File filter (e.g. *.py, tensor*, etc - only one filter allowed)"
            onChange={(e) => setFindFileFilter(e.target.value)}
          />
        )}
        {(selectedCodeAction === "findFile" ||
          selectedCodeAction === "findInFile") && (
          <Input
            label="Value to find"
            onChange={(e) => setFindValue(e.target.value)}
          />
        )}
        {(selectedCodeAction === "open" || selectedCodeAction === "edit") && (
          <div className="p-4">
            Selected file: {selectedCodeActionFileName ?? "None"}
          </div>
        )}
        {selectedCodeAction === "edit" && (
          <>
            <Textarea label="from" value={origCode} isReadOnly />
            <Textarea label="to" value={newCode} isReadOnly />
          </>
        )}
        {selectedCodeAction === "other" && (
          <>
            <Select
              label="Command Type"
              selectedKeys={[otherCommandType]}
              value={otherCommandType}
              onChange={(e) => setOtherCommandType(e.target.value)}
              aria-label="Select command type"
              className="p-2"
              classNames={{
                trigger: "bg-default-200",
                helperWrapper: "bg-default-200",
              }}
            >
              <SelectItem key="python" aria-label="python">
                python
              </SelectItem>
              <SelectItem key="bash" aria-label="bash">
                terminal
              </SelectItem>
            </Select>
            <Textarea
              className="p-2"
              label="Command"
              onChange={(e) => setOtherValue(e.target.value)}
            />
          </>
        )}

        <div className="flex justify-end space-x-4 p-3">
          <Button
            type="button"
            className="rounded-full flex items-center justify-center relative group border-1 border-white min-w-0"
            onClick={handleSendMessage}
            isDisabled={
              !thought ||
              loading ||
              !selectedCodeAction ||
              !userJustification.trim() ||
              (selectedCodeAction === "findFile" && !findValue) ||
              (selectedCodeAction === "findInFile" &&
                (!findFileFilter || !findValue)) ||
              (selectedCodeAction === "open" && !selectedCodeActionFileName) ||
              (selectedCodeAction === "other" &&
                (!otherCommandType || !otherValue)) ||
              (selectedCodeAction === "edit" &&
                (editing ||
                  !selectedCodeActionFileName ||
                  !selectedCodeActionOriginalCode ||
                  !selectedCodeActionReplacementCode))
            }
          >
            <VscSend className="w-4 h-4" />
            <span className="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 bg-black text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100">
              Submit Edit
            </span>
          </Button>
          <Button
            type="button"
            className="rounded-full flex items-center justify-center relative group border-1 border-white min-w-0"
            onClick={() => {
              dispatch(resetCodeActions());
              dispatch(setShowEditResponseModal(false));
            }}
            disabled={loading}
          >
            <VscClose className="w-4 h-4" />
            <Button className="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 bg-black text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100">
              Cancel
            </Button>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default EditorInterface;
