import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { I18nKey } from "#/i18n/declaration";
import { RootState } from "#/store";
import AgentState from "#/types/AgentState";
import { fetchLatestTrajectory } from "#/services/fileService";
import {
  setLoadedTrajectory,
  setTrajectoryNotification,
} from "#/state/trajectorySlice";

enum IndicatorColor {
  BLUE = "bg-blue-500",
  GREEN = "bg-green-500",
  ORANGE = "bg-orange-500",
  YELLOW = "bg-yellow-500",
  RED = "bg-red-500",
  DARK_ORANGE = "bg-orange-800",
}

function AgentStatusBar() {
  const { t } = useTranslation();
  const { curAgentState } = useSelector((state: RootState) => state.agent);
  const { curStatusMessage } = useSelector((state: RootState) => state.status);

  const dispatch = useDispatch();

  const AgentStatusMap: {
    [k: string]: { message: string; indicator: IndicatorColor };
  } = useMemo(
    () => ({
      [AgentState.INIT]: {
        message: t(I18nKey.CHAT_INTERFACE$AGENT_INIT_MESSAGE),
        indicator: IndicatorColor.BLUE,
      },
      [AgentState.RUNNING]: {
        message: t(I18nKey.CHAT_INTERFACE$AGENT_RUNNING_MESSAGE),
        indicator: IndicatorColor.GREEN,
      },
      [AgentState.AWAITING_USER_INPUT]: {
        message: t(I18nKey.CHAT_INTERFACE$AGENT_AWAITING_USER_INPUT_MESSAGE),
        indicator: IndicatorColor.ORANGE,
      },
      [AgentState.PAUSED]: {
        message: t(I18nKey.CHAT_INTERFACE$AGENT_PAUSED_MESSAGE),
        indicator: IndicatorColor.YELLOW,
      },
      [AgentState.LOADING]: {
        message: t(I18nKey.CHAT_INTERFACE$INITIALIZING_AGENT_LOADING_MESSAGE),
        indicator: IndicatorColor.DARK_ORANGE,
      },
      [AgentState.STOPPED]: {
        message: t(I18nKey.CHAT_INTERFACE$AGENT_STOPPED_MESSAGE),
        indicator: IndicatorColor.RED,
      },
      [AgentState.FINISHED]: {
        message: t(I18nKey.CHAT_INTERFACE$AGENT_FINISHED_MESSAGE),
        indicator: IndicatorColor.GREEN,
      },
      [AgentState.REJECTED]: {
        message: t(I18nKey.CHAT_INTERFACE$AGENT_REJECTED_MESSAGE),
        indicator: IndicatorColor.YELLOW,
      },
      [AgentState.ERROR]: {
        message: t(I18nKey.CHAT_INTERFACE$AGENT_ERROR_MESSAGE),
        indicator: IndicatorColor.RED,
      },
      [AgentState.AWAITING_USER_CONFIRMATION]: {
        message: t(
          I18nKey.CHAT_INTERFACE$AGENT_AWAITING_USER_CONFIRMATION_MESSAGE,
        ),
        indicator: IndicatorColor.ORANGE,
      },
      [AgentState.AWAITING_USER_REJECTION]: {
        message: t(
          I18nKey.CHAT_INTERFACE$AGENT_AWAITING_USER_REJECTION_MESSAGE,
        ),
        indicator: IndicatorColor.ORANGE,
      },
      [AgentState.USER_CONFIRMED]: {
        message: t(I18nKey.CHAT_INTERFACE$AGENT_ACTION_USER_CONFIRMED_MESSAGE),
        indicator: IndicatorColor.GREEN,
      },
      [AgentState.USER_REJECTED]: {
        message: t(I18nKey.CHAT_INTERFACE$AGENT_ACTION_USER_REJECTED_MESSAGE),
        indicator: IndicatorColor.ORANGE,
      },
      [AgentState.NEEDS_NUDGE]: {
        message: t(I18nKey.CHAT_INTERFACE$NEEDS_NUDGE),
        indicator: IndicatorColor.YELLOW,
      },
      [AgentState.NEEDS_EDIT]: {
        message: t(I18nKey.CHAT_INTERFACE$NEEDS_EDIT),
        indicator: IndicatorColor.YELLOW,
      },
      [AgentState.AWAITING_USER_NUDGE]: {
        message: t(I18nKey.CHAT_INTERFACE$NEEDS_NUDGE),
        indicator: IndicatorColor.YELLOW,
      },
      [AgentState.AWAITING_USER_EDIT]: {
        message: t(I18nKey.CHAT_INTERFACE$NEEDS_EDIT),
        indicator: IndicatorColor.YELLOW,
      },
      [AgentState.REVERTING]: {
        message: "Reverting...",
        indicator: IndicatorColor.YELLOW,
      },
      [AgentState.CLONING]: {
        message: "Cloning...",
        indicator: IndicatorColor.YELLOW,
      },
    }),
    [t],
  );

  useEffect(() => {
    const fetchLatest = async () => {
      const trajectory = await fetchLatestTrajectory();
      dispatch(setLoadedTrajectory(trajectory));
      dispatch(setTrajectoryNotification(true));
    };
    if (curAgentState === AgentState.FINISHED) {
      fetchLatest();
    }
  });

  const [statusMessage, setStatusMessage] = React.useState<string>("");

  React.useEffect(() => {
    const trimmedCustomMessage = curStatusMessage.status.trim();
    if (trimmedCustomMessage) {
      setStatusMessage(t(trimmedCustomMessage));
    } else {
      setStatusMessage(AgentStatusMap[curAgentState].message);
    }
  }, [AgentStatusMap, curAgentState, curStatusMessage.status, t]);

  return (
    <div className="flex flex-col items-center w-full">
      <div className="flex items-center">
        <div
          className={`w-3 h-3 mr-2 rounded-full animate-pulse ${AgentStatusMap[curAgentState].indicator}`}
        />
        <span className="text-sm text-stone-400">{statusMessage}</span>
      </div>
    </div>
  );
}

export default AgentStatusBar;
