import { createSlice } from "@reduxjs/toolkit";

export const problemInfoSlice = createSlice({
  name: "problemInfo",
  initialState: {
    uid: "",
    prURL: "",
    issueURL: "",
    problemStatement: "",
    goldenPatch: "",
    initialized: false,
  },
  reducers: {
    setProblemInfoState: (
      state,
      action: {
        payload: {
          uid: string;
          prURL: string;
          issueURL: string;
          problemStatement: string;
          goldenPatch: string;
        };
      },
    ) => {
      state.initialized = true;
      state.uid = action.payload.uid;
      state.prURL = action.payload.prURL;
      state.issueURL = action.payload.issueURL;
      state.problemStatement = action.payload.problemStatement;
      state.goldenPatch = action.payload.goldenPatch;
    },
  },
});

export const { setProblemInfoState } = problemInfoSlice.actions;

export default problemInfoSlice.reducer;
