enum AgentState {
  LOADING = "loading",
  INIT = "init",
  RUNNING = "running",
  AWAITING_USER_INPUT = "awaiting_user_input",
  PAUSED = "paused",
  STOPPED = "stopped",
  FINISHED = "finished",
  REJECTED = "rejected",
  ERROR = "error",
  AWAITING_USER_CONFIRMATION = "awaiting_user_confirmation",
  AWAITING_USER_REJECTION = "awaiting_user_rejection",
  USER_CONFIRMED = "user_confirmed",
  USER_REJECTED = "user_rejected",
  NEEDS_NUDGE = "needs_nudge",
  NEEDS_EDIT = "needs_edit",
  AWAITING_USER_NUDGE = "awaiting_user_nudge",
  AWAITING_USER_EDIT = "awaiting_user_edit",
  REVERTING = "reverting",
  CLONING = "cloning",
}

export default AgentState;
