import { v4 as uuidv4 } from "uuid";
import store from "#/store";
import { sendChatMessage } from "#/services/chatService";
import {
  addEdit,
  disablePreviousMessage,
  setShowEditResponseModal,
} from "#/state/chatSlice";
import { resetCodeActions } from "#/state/codeSlice";
import { request } from "./api";

export async function edit(
  thought: string,
  userJustification: string,
  commandType: string,
  action: string,
): Promise<number> {
  store.dispatch(disablePreviousMessage());

  const uuid = uuidv4();
  let userMessage = `**User provided response:** \n\n${thought}\n\n`;
  if (userJustification) {
    userMessage += `**Justification:** ${userJustification}\n\n`;
  }
  userMessage += `\`\`\`\`${commandType}\n${action}\n\`\`\`\`\n`;
  const timestamp = new Date().toISOString();

  await request("/api/edit", {
    method: "POST",
    body: JSON.stringify({
      thought,
      userJustification,
      commandType,
      action,
      uuid,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });

  store.dispatch(addEdit({ userMessage, uuid }));

  sendChatMessage(action, [], timestamp, thought, commandType, uuid);
  store.dispatch(resetCodeActions());
  store.dispatch(setShowEditResponseModal(false));
  return 1;
}
