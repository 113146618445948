import {
  addUserMessage,
  commitStep,
  removeMessagesAfterUUID,
  setRevertDispatched,
  setRevertUUID,
} from "#/state/chatSlice";
import { setRepoIsLoaded, setShowLoadRepoModal } from "#/state/repoSlice";
import store from "#/store";
import AgentState from "#/types/AgentState";
import { changeAgentState } from "./agentStateService";
import { request } from "./api";
import { sendChatMessage } from "./chatService";

export async function checkout(
  hash: string,
  uuid: string,
  userJustification: string,
): Promise<number> {
  changeAgentState(AgentState.REVERTING);
  store.dispatch(setRevertDispatched(true));
  store.dispatch(setRevertUUID(uuid));
  const encodedUserJustification = encodeURIComponent(userJustification);
  const url = `/api/checkout?hash=${hash}&uuid=${uuid}&user_justification=${encodedUserJustification}`;
  const data = await request(url);
  store.dispatch(removeMessagesAfterUUID());
  changeAgentState(AgentState.AWAITING_USER_CONFIRMATION);
  store.dispatch(setRevertDispatched(false));
  store.dispatch(setRevertUUID(""));
  return data;
}

export async function clone(
  githubUrl: string,
  problemStatement: string,
  issueCommit: string | null = null,
): Promise<void> {
  let commitHash = issueCommit;
  changeAgentState(AgentState.CLONING);

  let url = `/api/clone?githubUrl=${encodeURIComponent(githubUrl)}`;
  if (issueCommit) {
    url += `&issueCommit=${issueCommit}`;
  }
  const data = await request(url);
  commitHash = issueCommit ?? data;

  store.dispatch(setShowLoadRepoModal(false));
  store.dispatch(setRepoIsLoaded(true));

  const content = problemStatement;
  const imageUrls: string[] = [];
  const timestamp = new Date().toISOString();
  store.dispatch(
    addUserMessage({
      content,
      imageUrls,
      timestamp,
    }),
  );

  store.dispatch(commitStep(commitHash));
  sendChatMessage(content, imageUrls, timestamp);
}
